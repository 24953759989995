import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { UploadedImageThumbnails } from '~/components/MetadataFields/components/UploadedImageThumbnails'
import { SectionHeader } from '~/components/Panels/SelectedProductPanel/components/SectionHeader'
import { objectStateKeyFromClassName } from '~/store/objects/selectors'
import { Metadata } from '~/store/objects/types'
import { Button } from '~/ui/Button'
import { Icon } from '~/ui/Icon'
import { TextField } from '~/ui/TextField'

const IMAGE_ROUTES = {
  upload: '/upload-image/metadata',
  capture: '/webcam-capture/metadata',
}

type SelectedObject = {
  id: string
  metadata?: Metadata
  className?: string
}

type FieldName = keyof Pick<Metadata, 'notes' | 'title'>

type MetadataFieldProps<T extends SelectedObject> = {
  onChange: (fieldName: FieldName, newValue: string) => void
  onDeleteImage: (updatedObject: T) => void
  selectedObjects: T[]
}

export const MetadataFields = <T extends SelectedObject>({
  onChange,
  onDeleteImage,
  selectedObjects,
}: MetadataFieldProps<T>) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  const basePath = location.pathname.replace(/\/+$/, '')
  const selectedObject = selectedObjects.length === 1 ? selectedObjects[0] : null
  const metadata = selectedObject?.metadata

  const handleInputChange = (fieldName: FieldName) => (newValue: string) => {
    onChange(fieldName, newValue)
  }

  const handleDeleteImage = (cloudinaryId: string) => {
    if (!metadata) return
    const updatedObject = {
      ...selectedObject,
      metadata: {
        ...metadata,
        images: metadata.images?.filter(img => img.cloudinaryId !== cloudinaryId),
      },
    }
    onDeleteImage(updatedObject)
  }

  if (!selectedObject) return null

  const isUntouchedMetadata = !metadata?.notes && !metadata?.title && !metadata?.images?.length

  return (
    <div className="absolute bottom-0 right-0 left-0 w-full bg-white">
      <SectionHeader
        className="cursor-pointer flex gap-1"
        onClick={() => setIsOpen(o => !o)}
        role="button"
      >
        <Icon name={isUntouchedMetadata ? 'plus' : 'edit'} size="14" />
        <p>Metadata</p>
        <Icon className={`ml-auto ${isOpen ? 'rotate-180' : ''}`} name="arrowUp" size="14" />
      </SectionHeader>
      {isOpen && (
        <div className="p-3 flex flex-col gap-3 h-[290px] overflow-y-auto">
          <TextField
            label="Title"
            variant="normal"
            value={selectedObject.metadata?.title}
            onChange={handleInputChange('title')}
          />
          <TextField
            rows={3}
            label="Notes"
            variant="normal"
            value={selectedObject.metadata?.notes}
            onChange={handleInputChange('notes')}
          />
          {metadata?.images ? (
            <UploadedImageThumbnails
              selectedObjectId={selectedObject.id}
              selectedObjectType={objectStateKeyFromClassName(selectedObject.className)}
              onDelete={handleDeleteImage}
              images={metadata.images}
            />
          ) : null}
          <div className="flex gap-3">
            <Button variant="small" onPress={() => navigate(`${basePath}${IMAGE_ROUTES.upload}`)}>
              <Icon name="cloudUpload" size="14" />
              Upload Image
            </Button>
            <Button variant="small" onPress={() => navigate(`${basePath}${IMAGE_ROUTES.capture}`)}>
              <Icon name="camera" size="14" />
              Take Photo
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
