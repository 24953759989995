import { Vector3 } from "three"
import { ConvexGeometry } from "three/examples/jsm/Addons.js"
import LayerKeys from "~/config/layerKeys"
import ObjectClassName from "~/config/objectClassNames"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { Ceiling } from "~/store/objects/types"
import { selectObjects } from "~/store/selectedObjects"
import { modelToUI } from "../util/units"
import { useState } from "react"

export function Ceilings() {
  const isVisible = useAppSelector(it => it.layers.layers[LayerKeys.CEILINGS].visible)
  const ceilings = useAppSelector(it => it.objects.present.ceilings)

  if (!isVisible) {
    return <></>
  }

  return (
    <>
      {Object.entries(ceilings).map(([key, value]) => <ACeiling key={key} ceiling={value}/>)}
    </>
  )
}

function ACeiling(props: { ceiling: Ceiling }) {
  const points = props.ceiling.perimeterPoints
  const geometry = new ConvexGeometry(points.map(pt => new Vector3(pt.x, pt.y, modelToUI(props.ceiling.height))))
  const [hovered, setHovered] = useState(false)
  const selected = useAppSelector(it => it.selectedObjects.some(sel => sel.id === props.ceiling.id))
  const dispatch = useAppDispatch()
  const enabled = props.ceiling.enabled

  return (
    <mesh geometry={geometry} onClick={() => {
      dispatch(selectObjects({
        objects: [{ id: props.ceiling.id, className: ObjectClassName.CEILING }]
      }))
    }} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
      <meshStandardMaterial color={props.ceiling.color} opacity={(
        enabled ?
          selected ? 0.7 : hovered ? 0.55 : 0.5 :
          selected ? 0.4 : hovered ? 0.2 : 0.0
      )} transparent/>
    </mesh>
  )
}
