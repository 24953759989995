import React from 'react';
import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
  TextArea,
  ValidationResult
} from 'react-aria-components';
import { tv } from 'tailwind-variants';
import { Description, FieldError, Input, Label, fieldBorderStyles } from './Field';
import { composeTailwindRenderProps, focusRing } from './utils';

const inputStyles = tv({
  extend: focusRing,
  base: '',
  variants: {
    variant: {
      normal: 'border rounded',
      dimension: 'border px-0 py-0.5 text-center',
    },
    isFocused: fieldBorderStyles.variants.isFocusWithin,
    ...fieldBorderStyles.variants,
  }
});

export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  size?: number
  variant?: 'normal' | 'dimension'
  rows?: number
}

export function TextField(
  { label, description, errorMessage, rows, ...props }: TextFieldProps
) {
  return (
    <AriaTextField {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-1')}>
      {label && <Label>{label}</Label>}
      {rows ? (
        <TextArea rows={rows} className={renderProps => inputStyles({...renderProps, variant: props.variant})} />
       ) : (
        <Input className={renderProps => inputStyles({...renderProps, variant: props.variant})} size={props.size}/>
       )
      }
      {description && <Description>{description}</Description>}
      <FieldError>{errorMessage}</FieldError>
    </AriaTextField>
  );
}
