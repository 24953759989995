import { useAppDispatch, useAppSelector } from "~/store/hooks";

import { updateRoof } from 'store/objects'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { SELECTED_ROOF_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'
import theme from 'config/theme'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import { ColorPicker } from 'react-aria-components'
import { SwatchWell } from '~/ui/Color'
import { Label } from '~/ui/Field'
import MetadataSection from 'components/MetadataSection'
import { Roof } from '~/store/objects/types';

export default function SelectedRoofPanel() {
  const roof: Roof|undefined = useAppSelector(it => mostRecentSelectedObjectOfClassName(CLASS_NAMES.ROOF, it))
  const isTouchUI = useAppSelector(it => it.userInterface.isTouchUI)
  const isLocked = useAppSelector(it => it.layers.layers.ROOFS.locked)
  const dispatch = useAppDispatch()
  const update = (roof: Roof) => dispatch(updateRoof({ roof }))

  return (
    <Panel title="Roof" alignment="right" docked panelKey={SELECTED_ROOF_PANEL} isTouchUI={isTouchUI}>
      {roof && <>
        <PanelSection>
          <ColorPicker value={typeof roof.color === 'number' ? theme.colors.three.objects.roof.default : roof.color} onChange={color => update({ ...roof, color: color.toString("hex") })}>
            <Label>Color</Label>
            <SwatchWell swatches={theme.colors.swatches}/>
          </ColorPicker>
        </PanelSection>
        <MetadataSection object={roof} onBlur={update} disabled={isLocked}/>
      </>}
    </Panel>
  )
}
