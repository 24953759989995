import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAppSelector } from '~/store/hooks'
import Facility from '~/components/DrawingCanvas/lib/facility'
import { HEIGHT_VALUES, SIMULATION_TYPES } from '~/config/cfd'
import { useFacilityDefaults } from '~/hooks/useFacilityDefaults'
import UploadsWorker from './workers/modelFile.worker?worker'
import { CFDUpload, CFDUploads } from '~/hooks/contexts/CFDUploadsContext/util/types'
import { getBuildingJson } from '~/hooks/contexts/CFDUploadsContext/util/getBuildingJson'
import { formatProducts } from '~/hooks/contexts/CFDUploadsContext/util/formatProducts'
import { getFansJson } from '~/hooks/contexts/CFDUploadsContext/util/getFansJson'
import { getFacilityClone } from '~/hooks/contexts/CFDUploadsContext/util/getFacilityClone'
import { WorkerOutput } from '~/hooks/contexts/CFDUploadsContext/workers/modelFile.worker'

export const defaultEvaluationHeights = Array.from(
  new Set([...HEIGHT_VALUES.standing, ...HEIGHT_VALUES.seated])
).sort((a, b) => a - b)

type AdvancedClimate = {
  indoorSummerTemp: undefined | number
  indoorWinterTemp: undefined | number
  indoorHumidity: undefined | number
  indoorWinterHumidity: undefined | number
}

const defaultAdvancedClimate: AdvancedClimate = {
  indoorSummerTemp: undefined,
  indoorWinterTemp: undefined,
  indoorHumidity: undefined,
  indoorWinterHumidity: undefined,
}

type EvaluationHeights = {
  startingHeight: number
  customHeight: undefined | number
}

type CFDUploadsContextValue = {
  configure: {
    evaluationHeights: EvaluationHeights
    handleUpdateEvaluationHeights: (updates: Partial<EvaluationHeights>) => void
    advancedClimate: AdvancedClimate
    handleUpdateAdvancedClimate: (updates: Partial<AdvancedClimate>) => void
    generateAirVelocityProfile: boolean
    setGenerateAirVelocityProfile: Dispatch<SetStateAction<boolean>>
    handleRerenderOnFacilityLoad: () => void
  }
} & CFDUploads

export const CFDUploadsContext = createContext<null | CFDUploadsContextValue>(null)

interface Worker {
  postMessage(
    message: { model: {}; productsFiles: Awaited<ReturnType<typeof getFansJson>> },
    options?: StructuredSerializeOptions
  ): void
  terminate(): void
}

const getModelWorker = (): [Worker, Promise<WorkerOutput>] => {
  const worker = new UploadsWorker()
  const promise = new Promise<WorkerOutput>((resolve, reject) => {
    worker.onmessage = ({ data }) => resolve(data)
    worker.onerror = ({ error }) => reject(error)
  })
  return [worker, promise]
}

export const CFDUploadsProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: facilityDefaults, error, loading } = useFacilityDefaults()
  const products = useAppSelector((state: any) => state.objects.present.products)
  const ceilings = useAppSelector(state => state.objects.present.ceilings)
  const obstructions = useAppSelector((state: any) => state.objects.present.obstructions)
  const saving = useAppSelector((state: any) => state.objectsPersistence.isSaving)
  const { TEMPERATURE } = useAppSelector((state: any) => state.units)

  const [uploads, setUploads] = useState<CFDUploads>({
    loading: true,
    error: undefined,
    uploads: undefined,
  })
  const [evaluationHeights, setEvaluationHeights] = useState<EvaluationHeights>({
    startingHeight: 0,
    customHeight: undefined,
  })
  const [advancedClimate, setAdvancedClimate] = useState<AdvancedClimate>(defaultAdvancedClimate)
  const [generateAirVelocityProfile, setGenerateAirVelocityProfile] = useState(false)
  const [facilityInstantiationCounter, setFacilitiyInstantiationCounter] = useState(0)

  /** TODO: Remove this once Facility.current has been refactored. This forces the drawing canvas to rerender once 
   * Facility.current has been instantiated, which helps the context to have access to it when it prepares the uploads.
   */
  const handleRerenderOnFacilityLoad = useCallback(
    () => setFacilitiyInstantiationCounter(c => c + 1),
    []
  )

  const handleUpdateAdvancedClimate = useCallback((updates: Partial<AdvancedClimate>) => {
    setAdvancedClimate(prev => ({ ...prev, ...updates }))
  }, [])

  const handleUpdateEvaluationHeights = useCallback((updates: Partial<EvaluationHeights>) => {
    setEvaluationHeights(prev => ({ ...prev, ...updates }))
  }, [])

  useEffect(() => {
    // @ts-ignore @TODO: explicitly type "current" as facility on class
    const facility = Facility.current as Facility
    if (!facility || !facilityDefaults || !products) return

    const controller = new AbortController()
    const [worker, promise] = getModelWorker()

    const { startingHeight, customHeight } = evaluationHeights
    const planeHeights = [...defaultEvaluationHeights, ...(customHeight ? [customHeight] : [])].map(
      height => startingHeight + height
    )

    setUploads(prev => ({ ...prev, loading: true }))

    ;(async () => {
      if (saving) {
        return
      }
      try {
        const formattedProducts = await formatProducts(facility, products, ceilings)
        const mergedDefaults = {
          ...facilityDefaults,
          indoorSummerTemp: advancedClimate.indoorSummerTemp ?? facilityDefaults?.indoorSummerTemp,
          indoorWinterTemp: advancedClimate.indoorWinterTemp ?? facilityDefaults?.indoorWinterTemp,
          indoorHumidity: advancedClimate.indoorHumidity ?? facilityDefaults?.indoorHumidity,
          indoorWinterHumidity:
            advancedClimate.indoorWinterHumidity ?? facilityDefaults?.indoorWinterHumidity,
        }
        const productsInput = await getFansJson({
          facilityDefaults: mergedDefaults,
          formattedProducts,
          temperatureUnits: TEMPERATURE,
        })
        const { facilityClone, locations } = await getFacilityClone(facility, {
          productPositions: formattedProducts,
          obstructions,
          ceilings,
        })
        const buildingFile = getBuildingJson({
          facilityDefaults: mergedDefaults,
          generateAirVelocityProfile,
          locations,
          planeHeights,
        })
        worker.postMessage({ model: facilityClone.toJSON(), productsFiles: productsInput })
        const { checksums, modelUpload } = await promise
        const cfdUploads = Object.entries(productsInput).map<
          [keyof typeof SIMULATION_TYPES, CFDUpload]
        >(([simType, productsUpload]) => {
          if (!(simType in SIMULATION_TYPES)) throw new Error('oops')
          const cfdUpload = {
            model: modelUpload,
            products: productsUpload,
            building: buildingFile,
            checksum: checksums[simType as keyof typeof SIMULATION_TYPES],
          }
          return [simType as keyof typeof SIMULATION_TYPES, cfdUpload]
        })

        setUploads({ loading: false, uploads: Object.fromEntries(cfdUploads), error: undefined })
      } catch (e) {
        console.error(e)
        const error = e instanceof Error ? e : new Error('Failed to check model validity')
        setUploads({ loading: false, error, uploads: undefined })
      }
    })()

    return () => {
      worker.terminate()
      controller.abort()
    }
  }, [
    facilityInstantiationCounter,
    facilityDefaults,
    advancedClimate,
    products,
    obstructions,
    TEMPERATURE,
    evaluationHeights,
    generateAirVelocityProfile,
    saving,
    ceilings,
  ])

  const value = useMemo(
    () => ({
      configure: {
        evaluationHeights,
        handleUpdateEvaluationHeights,
        advancedClimate: {
          indoorSummerTemp: advancedClimate.indoorSummerTemp ?? facilityDefaults?.indoorSummerTemp,
          indoorWinterTemp: advancedClimate.indoorWinterTemp ?? facilityDefaults?.indoorWinterTemp,
          indoorHumidity: advancedClimate.indoorHumidity ?? facilityDefaults?.indoorHumidity,
          indoorWinterHumidity:
            advancedClimate.indoorWinterHumidity ?? facilityDefaults?.indoorWinterHumidity,
        },
        handleUpdateAdvancedClimate,
        generateAirVelocityProfile,
        setGenerateAirVelocityProfile,
        handleRerenderOnFacilityLoad,
      },
      ...uploads,
    }),
    [
      facilityDefaults,
      uploads,
      evaluationHeights,
      handleUpdateEvaluationHeights,
      advancedClimate,
      handleUpdateAdvancedClimate,
      generateAirVelocityProfile,
      setGenerateAirVelocityProfile,
      handleRerenderOnFacilityLoad,
    ]
  )
  return <CFDUploadsContext.Provider value={value}>{children}</CFDUploadsContext.Provider>
}
