import { createContext, PropsWithChildren } from 'react'
import { Product } from '~/store/objects/types'

type SelectedProductContextProps = {
  selectedProducts: Product[]
  productType:
    | Exclude<Product['layerKey'], 'PRODUCTS_HEATERS'>
    | 'PRODUCTS_UNIT_HEATERS'
    | 'PRODUCTS_RADIANT_HEATERS'
}

type SelectedProductContextValue = {
  productId: string | null
  variationId: string | null
  voltageId: string | null
  isForcedWallMount: boolean
  isDirectionalOverhead: boolean
  isHeater: boolean
} & SelectedProductContextProps

type ReducedSelectedProducts = {
  productIds: Set<string>
  variationIds: Set<string>
  voltageIds: Set<string>
}

export const SelectedProductContext = createContext<SelectedProductContextValue | null>(null)

export const SelectedProductProvider = ({
  children,
  productType,
  selectedProducts,
}: PropsWithChildren<SelectedProductContextProps>) => {
  const isForcedWallMount = selectedProducts.every(p => p.isForcedWallMount)
  const isDirectionalOverhead = selectedProducts.every(p => p.isDirectionalOverhead)
  const reducedProducts = selectedProducts.reduce<ReducedSelectedProducts>(
    (prev, product) => {
      product.productId && prev.productIds.add(product.productId)
      product.variationId && prev.variationIds.add(product.variationId)
      product.voltageId && prev.voltageIds.add(product.voltageId)
      return prev
    },
    {
      productIds: new Set(),
      variationIds: new Set(),
      voltageIds: new Set(),
    }
  )
  const productIds = Array.from(reducedProducts.productIds)
  const productId = productIds.length === 1 ? productIds[0] : null

  const variationIds = Array.from(reducedProducts.variationIds)
  const variationId = variationIds.length === 1 ? variationIds[0] : null

  const voltageIds = Array.from(reducedProducts.voltageIds)
  const voltageId = voltageIds.length === 1 ? voltageIds[0] : null

  return (
    <SelectedProductContext.Provider
      value={{
        productType,
        selectedProducts,
        variationId,
        productId,
        voltageId,
        isDirectionalOverhead,
        isForcedWallMount,
        isHeater:
          productType === 'PRODUCTS_RADIANT_HEATERS' || productType === 'PRODUCTS_UNIT_HEATERS',
      }}
    >
      {children}
    </SelectedProductContext.Provider>
  )
}
