import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions'

export const HeightFromFloor = () => {
  const { selectedProducts } = useSelectedProductContext()
  const { displayFormattedValue } = useDistanceDimensions()
  const selectedProduct = selectedProducts.length === 1 ? selectedProducts[0] : null

  return selectedProduct ? (
    <div className="flex justify-between items-center my-1">
      <p className="font-bold text-black text-xs">Height From Floor:</p>
      <p className="text-base">{displayFormattedValue(selectedProduct.position.z)}</p>
    </div>
  ) : (
    <></>
  )
}
