import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { NumberField, NumberFieldProps } from '~/ui/NumberField'

export const ProductRotationField = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts } = useSelectedProductContext()
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isUnitHeater = productType === 'PRODUCTS_UNIT_HEATERS'
  const isEvap = productType === 'PRODUCTS_EVAP'

  const zRotationValues = Array.from(new Set(selectedProducts.map(p => p.rotation.z)))
  const zRotation = zRotationValues.length === 1 ? zRotationValues[0] : undefined

  const handleRotate: NumberFieldProps['onChange'] = (degrees: number) => {
    dispatch(
      updateProducts(
        selectedProducts.map(product => ({
          ...product,
          rotation: {
            x: product.rotation.x,
            y: product.rotation.y,
            z: degrees,
          },
        }))
      )
    )
  }

  return isDirectional || isUnitHeater || isEvap ? (
    <NumberField
      isDisabled={isLocked}
      label="Rotation"
      value={zRotation}
      formatOptions={{ style: 'unit', unit: 'degree', unitDisplay: 'narrow' }}
      onChange={handleRotate}
    />
  ) : (
    <></>
  )
}
