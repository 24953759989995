import { useQuery } from '@apollo/client'
import { Key } from 'react-aria-components'
import { GET_DISTINCT_PRODUCT_FAN_SPEEDS } from '~/client/queries'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { Select, SelectItem } from '~/ui/Select'

const sortPercentagesBeforeWords = ({ id: idA }: { id: string }, { id: idB }: { id: string }) => {
  const aSpeedName = parseInt(idA)
  const bSpeedName = parseInt(idB)
  const compareValue = aSpeedName - bSpeedName
  if (isNaN(aSpeedName) && !isNaN(bSpeedName)) {
    return 1
  } else if (!isNaN(aSpeedName) && isNaN(bSpeedName)) {
    return -1
  } else if (!isNaN(compareValue)) {
    return compareValue
  } else {
    return idA.localeCompare(idB)
  }
}

export const FanSpeedSelects = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const {
    isHeater,
    productId,
    variationId,
    productType,
    isDirectionalOverhead,
    selectedProducts,
  } = useSelectedProductContext()

  const isDisabled = isLocked || !variationId
  const isEvap = productType === 'PRODUCTS_EVAP'
  const isCoolingOnlyDirectional = productType === 'PRODUCTS_DIRECTIONAL' && !isDirectionalOverhead
  const coolingSpeedIds = Array.from(new Set(selectedProducts.map(p => p.coolingFanSpeedId)))
  const coolingSpeedId = coolingSpeedIds.length === 1 ? coolingSpeedIds[0] : null
  const destratSpeedIds = Array.from(new Set(selectedProducts.map(p => p.destratFanSpeedId)))
  const destratSpeedId = destratSpeedIds.length === 1 ? destratSpeedIds[0] : null
  const { data } = useQuery(GET_DISTINCT_PRODUCT_FAN_SPEEDS, {
    variables: { productId: productId ?? '' },
    skip: !productId || !variationId,
  })
  const fanSpeeds = (data?.distinctProductFanSpeeds ?? [])
    .filter((fanSpeed): fanSpeed is { id: string; speed: string } => !!fanSpeed.speed)
    .map(({ speed }) => ({ id: speed }))
    .sort(sortPercentagesBeforeWords)

  const handleSpeedChange = (
    key: keyof Pick<Product, 'coolingFanSpeedId' | 'destratFanSpeedId'>
  ) => (newSpeedId: Key) => {
    if (typeof newSpeedId !== 'string') return
    dispatch(updateProducts(selectedProducts.map(product => ({ ...product, [key]: newSpeedId }))))
  }

  return !isHeater ? (
    <>
      <Select
        isDisabled={isDisabled}
        label="Cooling Speed"
        selectedKey={coolingSpeedId}
        items={fanSpeeds}
        onSelectionChange={handleSpeedChange('coolingFanSpeedId')}
      >
        {fanSpeed => <SelectItem value={fanSpeed}>{fanSpeed.id}</SelectItem>}
      </Select>
      {!isEvap && !isCoolingOnlyDirectional && (
        <Select
          isDisabled={isDisabled}
          label="Destrat Speed"
          selectedKey={destratSpeedId}
          items={fanSpeeds}
          onSelectionChange={handleSpeedChange('destratFanSpeedId')}
        >
          {fanSpeed => <SelectItem value={fanSpeed}>{fanSpeed.id}</SelectItem>}
        </Select>
      )}
    </>
  ) : (
    <></>
  )
}
