import { useApolloClient } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { GET_TUBE_LENGTHS_ON_VARIATION } from '~/client/queries'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Switch, SwitchProps } from '~/ui/Switch'

export const OverheadMountedSwitch = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const { productType, selectedProducts } = useSelectedProductContext()

  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isOverheadMountable = selectedProducts.every(({ canMountOverhead }) => canMountOverhead)

  const handleDirectionalOverheadChange: SwitchProps['onChange'] = async isSelected => {
    if (isLocked) return
    try {
      const updatedProducts = await Promise.all(
        selectedProducts.map(async product => {
          const { data } = await client.query({
            query: GET_TUBE_LENGTHS_ON_VARIATION,
            variables: { variationId: product.variationId },
          })
          const defaultVoltage = data.ProductVariation.voltages[0]
          const minFloorClearance = data.ProductVariation.minFloorClearance
          const tubeLength = defaultVoltage?.mountingOptions?.[0].tubeLength ?? 0
          return {
            ...product,
            rotation: {
              x: isSelected ? 0 : 90,
              y: product.rotation.y,
              z: product.rotation.z,
            },
            isDirectionalOverhead: isSelected,
            isForcedWallMount: isSelected ? false : product.isForcedWallMount,
            isMounted: isSelected ? false : product.isMounted,
            tubeLength,
            ...(isSelected
              ? {}
              : {
                // TODO: use product distance engine to get ceiling height
                  height: minFloorClearance,
                  position: { ...product.position, z: minFloorClearance },
                }),
          }
        })
      )
      dispatch(updateProducts(updatedProducts))
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Directional overhead toggle failed'
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change mounting option' }))
    }
  }

  return isDirectional && isOverheadMountable ? (
    <Switch
      isDisabled={isLocked}
      isSelected={selectedProducts.every(p => p.isDirectionalOverhead)}
      onChange={handleDirectionalOverheadChange}
    >
      Overhead Mounted
    </Switch>
  ) : (
    <></>
  )
}
