import { useQuery } from '@apollo/client'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { graphql } from '~/gql'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Switch, SwitchProps } from '~/ui/Switch'

export const ForceWallMountSwitch = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { productType, selectedProducts, variationId } = useSelectedProductContext()
  const { data } = useQuery(
    graphql(`
      query ProductVariationFloorClearance($variationId: ID!) {
        ProductVariation(id: $variationId) {
          id
          minFloorClearance
        }
      }
    `),
    { variables: { variationId: variationId ?? '' }, skip: !variationId }
  )
  const minFloorClearance = data?.ProductVariation.minFloorClearance

  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isForceMountable =
    selectedProducts.every(({ canMountOnWall }) => canMountOnWall) && !!variationId

  const handleForceWallMount: SwitchProps['onChange'] = isSelected => {
    if (isLocked || minFloorClearance == null) return
    const updatedProducts = selectedProducts.map(product => {
      const isLowRider = product.mountingOptionAdderId === '46'
      return {
        ...product,
        rotation: {
          x: !isSelected && isLowRider ? 90 : 0,
          y: product.rotation.y,
          z: product.rotation.z,
        },
        position: {
          ...product.position,
          z: minFloorClearance,
        },
        isMounted: isSelected,
        isDirectionalOverhead: isSelected ? false : product.isDirectionalOverhead,
        isForcedWallMount: isSelected,
      }
    })
    dispatch(updateProducts(updatedProducts))
  }

  return isDirectional && isForceMountable ? (
    <Switch
      isDisabled={isLocked}
      isSelected={selectedProducts.every(p => p.isForcedWallMount)}
      onChange={handleForceWallMount}
    >
      Force Wall Mount
    </Switch>
  ) : (
    <></>
  )
}
