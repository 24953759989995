import { ProductRotationField } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/ProductRotationField'
import { HeaterRotationSelect } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/HeaterRotationSelect'
import { TiltSelect } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/TiltSelect'
import { LouverAngleSelect } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/LouverAngleSelect'
import { ExtensionTubeSelect } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/ExtensionTubeSelect'
import { HeightField } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/HeightField'
import { OverheadMountedSwitch } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/OverheadMountedSwitch'
import { ForceWallMountSwitch } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/ForceWallMountSwitch'
import { MountingStructureSelect } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/MountingStructureSelect'
import { SectionHeader } from '~/components/Panels/SelectedProductPanel/components/SectionHeader'

export const MountingDetails = () => {
  return (
    <div>
      <SectionHeader>Mounting Details</SectionHeader>
      <div className="p-3 flex flex-col gap-3">
        <OverheadMountedSwitch />
        <ForceWallMountSwitch />
        <MountingStructureSelect />
        <ProductRotationField />
        <HeaterRotationSelect />
        <TiltSelect />
        <LouverAngleSelect />
        <ExtensionTubeSelect />
        <HeightField />
      </div>
    </div>
  )
}
