import { useApolloClient, useQuery } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { Key } from 'react-aria-components'
import { GET_TUBE_LENGTHS_ON_VARIATION, GET_VARIATION_VOLTAGES } from '~/client/queries'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { GetVariationVoltagesQuery } from '~/gql/graphql'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { Select, SelectItem } from '~/ui/Select'

const UNKNOWN_VOLTAGE: NonNullable<
  NonNullable<GetVariationVoltagesQuery['ProductVariation']>['voltages']
>[0] = {
  id: 'Unknown',
  inputPower: 'Unknown',
} as const

export const VoltageSelect = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const { selectedProducts, voltageId, variationId } = useSelectedProductContext()
  const { data } = useQuery(GET_VARIATION_VOLTAGES, {
    variables: { variationId: variationId ?? '' },
    skip: !variationId,
  })

  const isUnknownVoltage = selectedProducts.some(p => p.hasUnknownVoltage)
  const voltages = [UNKNOWN_VOLTAGE]
    .concat(data?.ProductVariation.voltages ?? [])
    .sort((a, b) => a.inputPower?.localeCompare(b?.inputPower ?? '') ?? 0)
  const isDisabled = isLocked || !variationId

  const handleVoltageChange = async (newVoltageId: Key) => {
    if (newVoltageId === UNKNOWN_VOLTAGE.id) {
      const updatedProducts = selectedProducts.map(p => ({ ...p, hasUnknownVoltage: true }))
      dispatch(updateProducts(updatedProducts))
      return
    }
    if (isDisabled || typeof newVoltageId !== 'string') return
    try {
      const selectedVoltage = voltages?.find(({ id }) => id === newVoltageId)
      if (!selectedVoltage) {
        throw new Error(`Failed to find voltage ${newVoltageId} on variation ${variationId}`)
      }
      const { data } = await client.query({
        query: GET_TUBE_LENGTHS_ON_VARIATION,
        variables: { variationId },
      })
      const mountingOptions = data?.ProductVariation.voltages.find(({ id }) => id === newVoltageId)
        ?.mountingOptions
      const updatedProducts: Product[] = selectedProducts.map(product => {
        const selectedMountingOption =
          mountingOptions?.find(({ id }) => id === product.mountingOptionId) ?? mountingOptions?.[0]
        return {
          ...product,
          voltage: selectedVoltage.inputPower ?? '',
          voltageId: newVoltageId,
          tubeLength: selectedMountingOption?.tubeLength ?? 0,
          mountingOptionId: selectedMountingOption?.id,
          hasUnknownVoltage: false,
        }
      })
      dispatch(updateProducts(updatedProducts))
    } catch (error) {
      const message = error instanceof Error ? error.message : `Missing voltage: ${newVoltageId}`
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change voltage' }))
    }
  }

  return (
    <Select
      label="Voltage"
      isDisabled={isDisabled}
      selectedKey={isUnknownVoltage ? UNKNOWN_VOLTAGE.id : voltageId}
      onSelectionChange={handleVoltageChange}
      items={voltages}
    >
      {voltage => <SelectItem value={voltage}>{voltage.inputPower}</SelectItem>}
    </Select>
  )
}
