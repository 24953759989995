import { Component } from 'react'
import { appConnect } from "~/store/hooks";
import { compose } from 'redux'

import isEqual from 'lodash-es/isEqual'
import get from 'lodash-es/get'
import omit from 'lodash-es/omit'

import { updateObjects } from 'store/objects'
import { addLoadingDockDoor } from 'store/loadingDock'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { SELECTED_DOOR_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'

import Panel, { PanelSection } from 'components/UIKit/Panel'
import Space from 'components/UIKit/Space'

import EmptyPanelSection from '../EmptyPanelSection'

import MetadataSection from 'components/MetadataSection'
import SaveConfiguration from 'components/SaveConfiguration'
import { AppDispatch, RootState } from '~/store';
import { Door } from '~/store/objects/types';
import { DimensionInput } from '~/ui/DimensionInput';
import { Select, SelectItem } from '~/ui/Select';
import { isTouchUI } from '~/store/userInterface/selectors';

const dockDoorTypes = [
  {
    label: 'Rollback',
    value: 'rollbackDoor',
  },
  {
    label: 'Roll-up',
    value: 'rollupDoor',
  },
  {
    label: 'Vertical',
    value: 'verticalDoor',
  },
]

type DoorPanelProps = {
  alignment: string
  doorModels: RootState['objects']['present']['doors']
  selectedObject: Door
  selectedObjects: RootState['selectedObjects']
  doors: RootState['loadingDock']['doors']
  onUpdateDoor: (doors: Partial<Door>[]) => void
  onAddLoadingDockDoor: (e: unknown) => void
  isTouchUI: boolean
}
class SelectedDoorPanel extends Component<DoorPanelProps> {
  static defaultProps = {
    alignment: 'right',
  }

  handleSaveConfig = (onSuccess: () => void) => {
    const { selectedObject, onAddLoadingDockDoor } = this.props
    const doorType = get(selectedObject, 'doorType')

    if (doorType) {
      onAddLoadingDockDoor(selectedObject)
      if (onSuccess) onSuccess()
    }
  }

  getConfigFromObject(obj: any) {
    if (!obj) return

    return {
      ...omit(obj, [
        'id',
        'wallSegmentId',
        'position',
        'layerKey',
        'metadata',
        'rotation',
      ]),
    }
  }

  isDupeConfig() {
    const { doors, selectedObject } = this.props
    if (!doors) return false
    const selectedDoorConfig = this.getConfigFromObject(selectedObject)
    let isDupe = false

    Object.keys(doors).forEach((key, index) => {
      // @ts-expect-error loading dock has not been typed yet
      const config = this.getConfigFromObject(doors[key])
      if (isEqual(config, selectedDoorConfig)) isDupe = true
    })

    return isDupe
  }

  renderContent() {
    const {
      selectedObject,
      selectedObjects,
      onUpdateDoor,
    } = this.props
    const height = selectedObject && selectedObject.height
    const width = selectedObject && selectedObject.width
    const id = get(selectedObject, 'id')
    const doorType = get(selectedObject, 'doorType')

    return (
      <>
        <SaveConfiguration
          selectedObjectId={id}
          isDupeConfig={this.isDupeConfig()}
          onConfigurationSave={(onSuccess: any) => this.handleSaveConfig(onSuccess)}
        />
        <PanelSection>
          <>
            <Space bottom="base">
              <DimensionInput
                label="Height"
                name="height"
                value={height}
                onCommit={distance => {
                  const doors = selectedObjects.map(obj => ({
                    ...obj,
                    height: distance || 0,
                  }))
                  onUpdateDoor(doors)
                }}
              />
            </Space>
            <Space bottom="base">
              <DimensionInput
                label="Width"
                name="width"
                value={width}
                onCommit={distance => {
                  const doors = selectedObjects.map(obj => ({
                    ...obj,
                    width: distance || 0,
                  }))
                  onUpdateDoor(doors)
                }}
              />
            </Space>
            {doorType !== 'manDoor' && (
              <Select
                name="type"
                label="Type"
                placeholder=""
                selectedKey={doorType}
                onSelectionChange={doorType => {
                  const doors = selectedObjects.map(obj => ({
                    ...obj,
                    doorType: doorType as string,
                  }))
                  onUpdateDoor(doors)
                }}
              >
                {dockDoorTypes.map(({ value, label }) => <SelectItem id={value}>{label}</SelectItem>)}
              </Select>
            )}
          </>
        </PanelSection>
        <MetadataSection
          object={selectedObject}
          // @ts-expect-error metadatasection is not typed yet
          onBlur={obj => onUpdateDoor([obj])}
        />
      </>
    )
  }

  render() {
    const { doorModels, selectedObjects, alignment, isTouchUI } = this.props

    let content = null
    if (selectedObjects.length) {
      let manDoorCount = 0
      let dockDoorCount = 0

      selectedObjects.forEach(obj => {
        const door = doorModels[obj.id]
        const doorType = get(door, 'doorType')
        if (doorType === 'manDoor') {
          manDoorCount++
        } else {
          dockDoorCount++
        }
      })

      const doorTypesDiffer = manDoorCount && dockDoorCount
      if (doorTypesDiffer) {
        content = (
          <EmptyPanelSection icon="duplicate">
            Multiple door types selected.
          </EmptyPanelSection>
        )
      } else {
        content = this.renderContent()
      }
    }

    return (
      <Panel
        title="Door"
        alignment={alignment}
        docked
        panelKey={SELECTED_DOOR_PANEL}
        hasToolbar={isTouchUI}
      >
        {content}
      </Panel>
    )
  }
}

const mapStateToProps = ({
  objects,
  selectedObjects,
  loadingDock,
  ...store
}: RootState) => {
  const selectedObject = mostRecentSelectedObjectOfClassName(CLASS_NAMES.DOOR, {
    selectedObjects,
    objects,
  })!
  const doors = loadingDock.doors
  const doorModels = objects.present.doors
  return {
    doorModels,
    selectedObject,
    selectedObjects,
    isTouchUI: isTouchUI(store),
    doors,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onUpdateDoor(doors: Partial<Door>[]) {
    dispatch(updateObjects(doors))
  },
  onAddLoadingDockDoor(door: unknown) {
    dispatch(addLoadingDockDoor(door))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps)
)(SelectedDoorPanel)
