import { graphql } from '~/gql'

export const GET_VARIATION_VOLTAGES = graphql(`
  query GetVariationVoltages($variationId: ID!) {
    ProductVariation(id: $variationId) {
      id
      voltages {
        id
        inputPower
      }
    }
  }
`)
