import { Metadata } from '~/store/objects/types'
import cloudinary from 'config/cloudinary'
import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { Icon } from '~/ui/Icon'

type UploadedImageThumbnailsProps = {
  images: NonNullable<Metadata['images']>
  selectedObjectId: string
  selectedObjectType: string
  onDelete: (cloudinaryId: string) => void
}
export const UploadedImageThumbnails = ({
  images,
  onDelete,
  selectedObjectId,
  selectedObjectType,
}: UploadedImageThumbnailsProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <>
      <p className="text-xs font-bold">Images</p>
      <div className="grid grid-cols-3 gap-3 place-items-center">
        {images.map(({ cloudinaryId }, i) => {
          const { src } = cloudinary.image(cloudinaryId, {
            format: 'png',
            secure: true,
            transformation: [
              {
                width: 50,
                height: 50,
                crop: 'fill',
              },
            ],
          })
          const searchParams = new URLSearchParams({
            objectId: selectedObjectId,
            objectType: selectedObjectType,
            imageIndex: i.toString(),
          })
          const modalUrl = `${pathname.replace(/\/+$/, '')}/view-image?${searchParams.toString()}`
          return (
            <div className="relative w-fit">
              <Icon
                role="button"
                onClick={() => onDelete(cloudinaryId)}
                className="absolute rounded-full bg-gray-200 hover:bg-baf-yellow-600 cursor-pointer -right-[7px] -top-[7px]"
                name="cross"
                size="14"
              />
              <img
                role="button"
                onClick={() => navigate(modalUrl)}
                className="object-contain cursor-pointer w-[50px] h-[50px]"
                key={i}
                src={src}
                alt="metadata image"
              />
            </div>
          )
        })}
      </div>
    </>
  )
}
