import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { TextField } from '~/ui/TextField'

export const DetailedLabelField = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { selectedProducts } = useSelectedProductContext()
  const currentLabel = selectedProducts.length === 1 ? selectedProducts[0].detailedLabel ?? '' : ''

  const handleChange = (value: string) => {
    const updatedProducts = selectedProducts.map(product => ({ ...product, detailedLabel: value }))
    dispatch(updateProducts(updatedProducts))
  }

  return (
    <TextField
      isDisabled={isLocked}
      variant="normal"
      value={currentLabel}
      label="Detailed Label"
      onChange={handleChange}
    />
  )
}
