import { useLocation, useNavigate } from 'react-router-dom-v5-compat'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import routes from '~/config/routes'
import { useNetwork } from '~/networkProvider'
import { useAppSelector } from '~/store/hooks'
import { Product } from '~/store/objects/types'
import { Button } from '~/ui/Button'

type ProductInstallInfoFields = {
  [Key in keyof Pick<Product, 'deckHeight' | 'heightToAttachPoint' | 'liftNeeded'>]-?: Key
}

const INSTALL_INFO_FIELDS: ProductInstallInfoFields = {
  deckHeight: 'deckHeight',
  heightToAttachPoint: 'heightToAttachPoint',
  liftNeeded: 'liftNeeded',
} as const

export const AddProductInstallInfoButton = () => {
  const location = useLocation()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const navigate = useNavigate()
  const { online } = useNetwork()

  const { isDirectionalOverhead, selectedProducts, productType } = useSelectedProductContext()

  const selectedProduct = selectedProducts.length === 1 ? selectedProducts[0] : null
  const isInstallInfoEditable =
    !!selectedProduct &&
    !selectedProduct.model?.includes('Existing') &&
    !(productType === 'PRODUCTS_DIRECTIONAL' && !isDirectionalOverhead)
  const selectedProductId = selectedProduct ? selectedProduct.id : null
  const hasInstallInfo =
    !!selectedProduct &&
    Object.values(INSTALL_INFO_FIELDS).every(key => selectedProduct[key] != null)
  const action = hasInstallInfo ? (online ? 'Update' : 'View') : 'Add'
  const label = `${action} install information`

  const handleClick = () => {
    if (!selectedProductId) return
    const path = `${location.pathname.replace(/\/+$/, '')}${routes.modals.addProductInstallInfo}`
    const searchParams = new URLSearchParams({ objectId: selectedProductId })
    navigate(`${path}?${searchParams.toString()}`)
  }

  return (
    isInstallInfoEditable &&
    (online || hasInstallInfo) && (
      <Button className="mx-3" isDisabled={isLocked} onPress={handleClick}>
        {label}
      </Button>
    )
  )
}
