import { graphql } from '~/gql'

export const GET_DISTINCT_PRODUCT_FAN_SPEEDS = graphql(`
  query GetDistinctProductFanSpeeds($productId: ID!) {
    distinctProductFanSpeeds(id: $productId) {
      id
      speed
    }
  }
`)
