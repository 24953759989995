import { graphql } from '~/gql'

export const GET_MOUNTING_OPTIONS_ON_VARIATION = graphql(`
  query GetMountingOptionsOnVariation($variationId: ID!) {
    ProductVariation(id: $variationId) {
      id
      mountingOptionAdders {
        id
        mountingType
      }
    }
  }
`)
