import { HeightFromFloor } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/HeightFromFloor'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { DimensionInput } from '~/ui/DimensionInput'

export const HeightField = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts, isHeater } = useSelectedProductContext()
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isMounted = selectedProducts.every(p => p.isMounted)
  const isHornet = selectedProducts.every(p => p.model === 'Hornet')
  
  const nonNullHeights = selectedProducts.map(p => p.height).filter((h): h is number => h != null)
  const heights = Array.from(new Set(nonNullHeights))
  const height = heights.length === 1 ? heights[0] : 0

  const handleInput = (newHeight: number) => {
    dispatch(
      updateProducts(
        selectedProducts.map(product => ({
          ...product,
          height: newHeight,
        }))
      )
    )
  }

  return isHeater || isHornet || (isDirectional && isMounted) ? (
    <DimensionInput
      minLength={0}
      value={height}
      onCommit={handleInput}
      showStepperButtons
      label="Height From Floor"
      isDisabled={isLocked}
    />
  ) : (
    <HeightFromFloor />
  )
}
