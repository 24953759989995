import { graphql } from '~/gql'

export const GET_PRODUCT_VARIATION = graphql(`
  query GetProductVariation($variationId: ID!, $isHeater: Boolean!) {
    ProductVariation(id: $variationId) {
      ...ProductVariationFragment @skip(if: $isHeater)
      ...HeaterVariationFragment @include(if: $isHeater)
    }
  }
`)
