import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { updateCeiling } from '~/store/objects'
import { mostRecentSelectedObjectOfClassName } from '~/store/selectedObjects/selectors'
import { SELECTED_CEILING_PANEL } from '~/store/panel/types'
import CLASS_NAMES from '~/config/objectClassNames'
import Panel from '~/components/UIKit/Panel'
import MetadataSection from '~/components/MetadataSection'
import { SwatchWell } from '~/ui/Color'
import { ColorPicker } from 'react-aria-components'
import { Label } from '~/ui/Field'
import theme from '~/config/theme'
import { Switch } from '~/ui/Switch'
import { DimensionInput } from '~/ui/DimensionInput'

export default function SelectedCeilingPanel() {
  const ceiling = useAppSelector(it => mostRecentSelectedObjectOfClassName(CLASS_NAMES.CEILING, it))
  const locked = useAppSelector(it => it.layers.layers.CEILINGS.locked)
  const isTouchUI = useAppSelector(it => it.userInterface.isTouchUI)
  const dispatch = useAppDispatch()

  return (
    <Panel
      title="Ceiling"
      alignment="right"
      docked
      panelKey={SELECTED_CEILING_PANEL}
      hasToolbar={isTouchUI}
    >
      {ceiling && <>
        <div className="p-4 flex flex-col space-y-2">
          <Switch isSelected={ceiling.enabled} isDisabled={locked} onChange={selected => {
            dispatch(updateCeiling({
              ceiling: {
                ...ceiling,
                id: ceiling.id,
                enabled: selected,
              }
            }))
          }}>
            Enabled
          </Switch>
          <div>
            <ColorPicker value={ceiling.color} onChange={color => {
              dispatch(updateCeiling({
                ceiling: {
                  ...ceiling,
                  id: ceiling.id,
                  color: color.toString("hex"),
                }
              }))
            }}>
              <Label>Color</Label>
              <SwatchWell swatches={theme.colors.swatches} isDisabled={locked}/>
            </ColorPicker>
          </div>
          <DimensionInput
            label="Height"
            value={ceiling.height}
            onCommit={value => {
              dispatch(updateCeiling({
                ceiling: {
                  ...ceiling,
                  id: ceiling.id,
                  height: value,
                }
              }))
            }}
            isDisabled={!ceiling.enabled || locked}/>
        </div>
        <MetadataSection
          object={ceiling}
          onBlur={(data: unknown) => {
            dispatch(updateCeiling({
              ceiling: data,
            }))
          }}
          disabled={locked}
        />
      </>}
    </Panel>
  )
}
