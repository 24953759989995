import { useAppSelector } from '~/store/hooks'
import { MeasurementSystem } from '~/store/units/constants'

const CM_TO_INCHES = 2.54

const formatFeet = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
}).format

export const useFormatLength = () => {
  const distanceUnits = useAppSelector(state => state.units.DISTANCE)
  return (tubeLengthInInches: number) => {
    if (distanceUnits === MeasurementSystem.METRIC) {
      const cm = tubeLengthInInches * CM_TO_INCHES
      const m = (cm / 100).toFixed(2)
      return `${cm.toFixed(0)}cm (${m}m)`
    } else {
      return `${tubeLengthInInches}" (${formatFeet(tubeLengthInInches / 12)}ft)`
    }
  }
}
