import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Checkbox } from '~/ui/Checkbox'

export const IgnoreErrorsCheckbox = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { selectedProducts } = useSelectedProductContext()
  const isAcknowledged = selectedProducts.every(p => p.ignoreErrors)

  const handleCheck = (isSelected: boolean) => {
    dispatch(
      updateProducts(selectedProducts.map(product => ({ ...product, ignoreErrors: isSelected })))
    )
  }

  return (
    <Checkbox isDisabled={isLocked} isSelected={isAcknowledged} onChange={handleCheck}>
      <p className="text-xs">Acknowledge and Ignore Errors</p>
    </Checkbox>
  )
}
