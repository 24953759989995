import { Key } from 'react-aria-components'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'
import { formatDegrees } from '~/utils/number'

const HEATER_ROTATION_OPTIONS = [0, 90, 180, 270].map(rotation => ({ id: rotation }))

export const HeaterRotationSelect = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts } = useSelectedProductContext()
  const isRadiantHeater = productType === 'PRODUCTS_RADIANT_HEATERS'

  const yRotationValues = Array.from(new Set(selectedProducts.map(p => p.rotation.y)))
  const yRotation = yRotationValues.length === 1 ? yRotationValues[0] : null

  const handleSelectRotation = (newRotation: Key) => {
    if (typeof newRotation !== 'number' || isLocked) return
    dispatch(
      updateProducts(
        selectedProducts.map(product => ({
          ...product,
          rotation: {
            x: product.rotation.x,
            y: newRotation,
            z: product.rotation.z,
          },
        }))
      )
    )
  }

  return isRadiantHeater ? (
    <Select
      label="Rotation"
      isDisabled={isLocked}
      selectedKey={yRotation}
      onSelectionChange={handleSelectRotation}
      items={HEATER_ROTATION_OPTIONS}
    >
      {rotationOption => (
        <SelectItem value={rotationOption}>{formatDegrees(rotationOption.id)}</SelectItem>
      )}
    </Select>
  ) : (
    <></>
  )
}
