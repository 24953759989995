import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

export const SectionHeader = ({ className, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      className={twMerge(
        'p-2 bg-gray-100 text-gray-500 text-[10px] border-y border-gray-300',
        className
      )}
      {...props}
    />
  )
}
