import { DetailedLabelField } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/DetailedLabelField'
import { FanSpeedSelects } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/FanSpeedSelects'
import { FuelTypeSelect } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/FuelTypeSelect'
import { IgnoreErrorsCheckbox } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/IgnoreErrorsCheckbox'
import { ProductSizeSelect } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/ProductSizeSelect'
import { VoltageSelect } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx/VoltageSelect'
import { SectionHeader } from '~/components/Panels/SelectedProductPanel/components/SectionHeader'

export const ProductDetails = () => {
  return (
    <div>
      <SectionHeader>Product Details</SectionHeader>
      <div className="p-3 flex flex-col gap-3">
        <ProductSizeSelect />
        <VoltageSelect />
        <FuelTypeSelect />
        <FanSpeedSelects />
        <DetailedLabelField />
        <IgnoreErrorsCheckbox />
      </div>
    </div>
  )
}
