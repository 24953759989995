import { Key } from 'react-aria-components'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'
import { formatDegrees } from '~/utils/number'

const HEATER_TILT_OPTIONS = [0, 45, -45].map(tilt => ({ id: tilt }))

const DIRECTIONAL_TILT_VALUES = [0, 22.5, 45, 67.5, 90].map(tilt => ({ id: tilt }))

export const TiltSelect = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts } = useSelectedProductContext()
  const isRadiantHeater = productType === 'PRODUCTS_RADIANT_HEATERS'
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isEvap = productType === 'PRODUCTS_EVAP'
  const isAllTiltableProducts = selectedProducts.every(product => !!product.degreesOfFreedom)

  const xRotationValues = Array.from(new Set(selectedProducts.map(p => p.rotation.x)))
  const xRotation = xRotationValues.length === 1 ? xRotationValues[0] : null

  const handleSelectTilt = (newTilt: Key) => {
    if (typeof newTilt !== 'number' || isLocked) return
    dispatch(
      updateProducts(
        selectedProducts.map(product => ({
          ...product,
          rotation: {
            x: newTilt,
            y: product.rotation.y,
            z: product.rotation.z,
          },
        }))
      )
    )
  }

  return isRadiantHeater || isEvap || (isDirectional && isAllTiltableProducts) ? (
    <Select
      label="Tilt"
      isDisabled={isLocked}
      selectedKey={xRotation}
      onSelectionChange={handleSelectTilt}
      items={isRadiantHeater ? HEATER_TILT_OPTIONS : DIRECTIONAL_TILT_VALUES}
    >
      {tiltOption => <SelectItem value={tiltOption}>{formatDegrees(tiltOption.id)}</SelectItem>}
    </Select>
  ) : (
    <></>
  )
}
