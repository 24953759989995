import { ColorArea as AriaColorArea, ColorThumb as AriaColorThumb, ColorSlider as AriaColorSlider, SliderOutput as AriaSliderOutput, ColorSwatch as AriaColorSwatch, ColorSwatchPicker as AriaColorSwatchPicker, SliderTrack as AriaSliderTrack, ColorField as AriaColorField, ColorAreaProps, ColorSliderProps, ColorThumbProps, composeRenderProps, SliderOutputProps, SliderTrackProps, ColorFieldProps, ColorSwatchProps, ColorSwatchPickerProps, ColorSwatchPickerItem, Color, ButtonProps } from 'react-aria-components'
import { tv } from 'tailwind-variants'
import { composeTailwindRenderProps, focusRing } from './utils'
import { Input, FieldGroup, Label, labelStyles } from './Field'
import { ReactNode } from 'react'
import { Button } from './Button'
import { Popover } from './Popover'
import { Dialog } from './Dialog'
import { DialogTrigger } from './Modal'

const colorThumbStyles = tv({
  extend: focusRing,
  base: 'border-2 border-white w-5 h-5 rounded-full shadow-[0_0_0_1px_black,_inset_0-0_0_1px_black]'
})

function ColorThumb(props: ColorThumbProps) {
  return (
    <AriaColorThumb {...props} className={composeRenderProps(props.className, (className, renderProps) => colorThumbStyles({ ...renderProps, className }))}>
      {props.children}
    </AriaColorThumb>
  )
}

const colorAreaStyles = tv({
  base: 'w-64 h-48 rounded'
})

export function ColorArea(props: ColorAreaProps) {
  return (
    <AriaColorArea {...props} className={composeRenderProps(props.className, (className, renderProps) => colorAreaStyles({ ...renderProps, className }))}>
      {composeRenderProps(props.children, (children) => <>
        <ColorThumb />
        {children}
      </>)}
    </AriaColorArea>
  )
}

const colorSliderStyles = tv({
  base: 'w-64',
})

export function ColorSlider(props: ColorSliderProps & { showLabel?: boolean }) {
  return (
    <AriaColorSlider {...props} className={composeRenderProps(props.className, (className, renderProps) => colorSliderStyles({ ...renderProps, className }))}>
      {composeRenderProps(props.children, (children) => <>
        {props.showLabel && (<>
          <Label />
          <SliderOutput />
        </>)}
        <SliderTrack>
          <ColorThumb className="h-8 w-3 top-1/2"/>
        </SliderTrack>
        {children}
      </>)}
    </AriaColorSlider>
  )
}

export function SliderOutput(props: SliderOutputProps) {
  return (
    <AriaSliderOutput {...props} className={composeRenderProps(props.className, (className, renderProps) => labelStyles({ ...renderProps, className }))}>
      {props.children}
    </AriaSliderOutput>
  )
}

const sliderTrackStyles = tv({
  base: 'w-full h-7 rounded'
})

export function SliderTrack(props: SliderTrackProps) {
  return (
    <AriaSliderTrack {...props} className={composeRenderProps(props.className, (className, renderProps) => sliderTrackStyles({ ...renderProps, className }))}>
      {props.children}
    </AriaSliderTrack>
  )
}

export function ColorField(props: ColorFieldProps & { label?: ReactNode }) {
  return (
    <AriaColorField {...props} className={composeTailwindRenderProps(props.className, 'flex flex-col gap-1')}>
      {props.label && <Label>{props.label}</Label>}
      <FieldGroup>
        <Input/>
      </FieldGroup>
    </AriaColorField>
  )
}

export function ColorSwatch(props: ColorSwatchProps) {
  return (
    <AriaColorSwatch
      {...props}
      className={composeTailwindRenderProps(props.className, 'w-7 h-7 rounded border border-black/10')}
      style={({color}) => ({
        background: `linear-gradient(${color}, ${color}),
          repeating-conic-gradient(#CCC 0% 25%, white 0% 50%) 50% / 16px 16px`
      })}/>
  )
}

const colorSwatchPickerItemStyles = tv({
  extend: focusRing,
  base: 'border-2 border-transparent selected:border-white rounded-lg selected:shadow-[0_0_0_1px_black,_inset_0-0_0_1px_black]'
})

export function SwatchWell(props: ButtonProps & { swatches: (string | Color)[] }) {
  return (
    <DialogTrigger>
      <Button {...props} variant='icon' className="p-0">
        <ColorSwatch/>
      </Button>
      <Popover showArrow>
        <Dialog>
          <ColorSwatchPicker swatches={props.swatches}/>
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}

export function ChooserWell(props: ButtonProps & { swatches?: (string | Color)[] }) {
  return (
    <DialogTrigger>
      <Button {...props} variant='icon' className="p-0">
        <ColorSwatch/>
      </Button>
      <Popover showArrow>
        <Dialog className="space-y-2">
          <ColorChooser/>
          {props.swatches ? <ColorSwatchPicker swatches={props.swatches}/> : undefined}
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}

export function ColorChooser(props: { swatches?: (string | Color)[] }) {
  return (
    <div className="flex flex-col space-y-1">
      <ColorArea colorSpace="hsl" xChannel="saturation" yChannel="lightness"/>
      <div className="flex space-x-1">
        <ColorSlider colorSpace="hsl" channel="hue" className="w-full" />
        {props.swatches ? <SwatchWell swatches={props.swatches} /> : <ColorSwatch/>}
      </div>
      <ColorField className="w-full"/>
    </div>
  )
}

export function ColorSwatchPicker(props: ColorSwatchPickerProps & { swatches: (string | Color)[] }) {
  return (
    <AriaColorSwatchPicker {...props} className="grid grid-cols-8">
      {props.swatches.map(swatch => <>
        <ColorSwatchPickerItem color={swatch} className={renderProps => colorSwatchPickerItemStyles({ ...renderProps })} key={swatch.toString()}>
          <ColorSwatch/>
        </ColorSwatchPickerItem>
      </>)}
    </AriaColorSwatchPicker>
  )
}
