import { graphql } from '~/gql'

export const GET_VARIATION_SIZES = graphql(`
  query GetVariationSizes($productId: ID!, $isHeater: Boolean!) {
    Product(id: $productId) {
      id
      variations {
        id
        size
        label @include(if: $isHeater)
      }
    }
  }
`)
