import { useApolloClient, useQuery } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { useState } from 'react'
import { Key } from 'react-aria-components'
import { GET_PRODUCT_VARIATION, GET_VARIATION_SIZES } from '~/client/queries'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useCheckFoilCollisions } from '~/hooks/useCheckFoilCollisions'
import { useFormatLength } from '~/hooks/useFormatLength'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'
import { Select, SelectItem } from '~/ui/Select'

export const ProductSizeSelect = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const formatSize = useFormatLength()
  const checkFoilCollisions = useCheckFoilCollisions()
  const { isHeater, productId, selectedProducts, variationId } = useSelectedProductContext()
  const { data } = useQuery(GET_VARIATION_SIZES, {
    variables: { productId: productId ?? '', isHeater },
    skip: !productId,
  })

  const [isPending, setIsPending] = useState(false)

  const label = isHeater ? 'Size' : 'Diameter'
  const variations = data?.Product.variations ?? []
  const isDisabled = isLocked || isPending

  const handleChangeVariation = async (newVariationId: Key) => {
    if (typeof newVariationId !== 'string' || isDisabled) return
    try {
      setIsPending(true)
      const { ProductVariation } = (
        await client.query({
          query: GET_PRODUCT_VARIATION,
          variables: { variationId: newVariationId, isHeater },
        })
      ).data
      const updatedProducts = selectedProducts.map(product => {
        ProductVariation.voltages
        return {
          ...product,
          ...ProductVariation,
          id: product.id,
          variationId: ProductVariation.id,
          voltageId: ProductVariation.voltages[0]?.id,
          voltage: ProductVariation.voltages[0]?.inputPower,
          mountingOptionAdderId: ProductVariation.mountingOptionAdders[0]?.id ?? 'Unknown',
          // TODO: this cast behaves the same way as adding a product via setActiveTool.
          // That action's payload is currently untyped. Refactor this after the Product type is
          // defined to not include graphql data.
        } as unknown as Product
      })
      const isTouchingWall = updatedProducts.some(product => checkFoilCollisions(product))
      if (isTouchingWall) {
        const text = 'Resizing Fan Not Allowed Due To Size Restraints'
        dispatch(setStatus({ text, type: 'error' }))
      } else {
        dispatch(updateProducts(updatedProducts))
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : `Missing variation: ${newVariationId}`
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change product size' }))
    } finally {
      setIsPending(false)
    }
  }

  return (
    <Select
      label={label}
      selectedKey={variationId}
      onSelectionChange={handleChangeVariation}
      items={variations}
      isDisabled={isDisabled}
    >
      {variation => (
        <SelectItem value={variation}>
          {isHeater ? variation.label! : formatSize(variation.size)}
        </SelectItem>
      )}
    </Select>
  )
}
