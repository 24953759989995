import { useAppDispatch, useAppSelector } from '~/store/hooks'
import get from 'lodash-es/get'

import { updateProducts } from 'store/objects'
import { SELECTED_PRODUCT_PANEL } from 'store/panel/types'

import Panel from 'components/UIKit/Panel'

import { Icon } from '~/ui/Icon'
import { MountingDetails } from '~/components/Panels/SelectedProductPanel/components/MountingDetails'
import { SelectedProductProvider } from '~/components/Panels/SelectedProductPanel/components/SelectedProductContext'
import { ProductDropDown } from '~/components/Panels/SelectedProductPanel/components/ProductDropDown'
import { ProductDetails } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx'
import { AddProductInstallInfoButton } from '~/components/Panels/SelectedProductPanel/components/AddProductInstallInfoButton'
import { MetadataFields } from '~/components/MetadataFields'

export function existingVoltage<T extends { id?: string | null; inputPower?: string | null }>(
  voltages: T[],
  curVoltage: Partial<T> = {}
) {
  return voltages.find(v => {
    const hasSameId = v.id === curVoltage.id
    // Some voltages have a power using –
    // and others have a power using -
    // 😊
    const hasSamePower =
      (get(v, 'inputPower') || '').replace('–', '-') ===
      (get(curVoltage, 'inputPower') || '').replace('–', '-')

    return hasSameId || hasSamePower
  })
}

const SelectedProductPanel = () => {
  const dispatch = useAppDispatch()
  const isTouchUI = useAppSelector(state => state.userInterface.isTouchUI)
  const products = useAppSelector(state => state.objects.present.products)
  const selectedProducts = useAppSelector(state =>
    state.selectedObjects.map(({ id }) => products[id]).filter(product => !!product)
  )
  const selectedTypes = Array.from(
    new Set(
      selectedProducts.map(({ layerKey, model, category, product }) => {
        const isHeater = layerKey === 'PRODUCTS_HEATERS' || category === 'HEAT'
        if (isHeater) {
          return model!.includes('IRH') ? 'PRODUCTS_RADIANT_HEATERS' : 'PRODUCTS_UNIT_HEATERS'
        } else if (layerKey === 'PRODUCTS') {
          // TODO: the addProduct sets the layerKey to PRODUCTS. Products get updated on a
          // setTimeout with a more specific layer key. Until that gets refactored, we need to
          // explicitly set the type here. Note that heaters are taken care of above.
          return category === 'EVAP'
            ? 'PRODUCTS_EVAP'
            : product.type === 'OVERHEAD'
            ? 'PRODUCTS_OVERHEAD'
            : 'PRODUCTS_DIRECTIONAL'
        } else {
          return layerKey
        }
      })
    )
  )
  const selectedProductType = selectedTypes.length === 1 ? selectedTypes[0] : null
  const isMultipleVariationsSelected = selectedProducts.length > 0 && selectedTypes.length > 1

  return (
    <Panel
      title="Product"
      alignment={isTouchUI ? 'left' : 'right'}
      docked
      panelKey={SELECTED_PRODUCT_PANEL}
      scrollable
      hasToolbar={isTouchUI}
    >
      {isMultipleVariationsSelected || !selectedProductType ? (
        <div className="opacity-35 flex flex-col items-center gap-5 my-10">
          <Icon name="duplicate" size="30" />
          <p>Multiple product types selected.</p>
        </div>
      ) : (
        <div className="flex flex-col h-full pb-10 overflow-y-auto">
          <SelectedProductProvider
            selectedProducts={selectedProducts}
            productType={selectedProductType}
          >
            <ProductDropDown />
            <MountingDetails />
            <ProductDetails />
            <AddProductInstallInfoButton />
            <MetadataFields
              onChange={(fieldName, newValue) => {
                const updatedProducts = selectedProducts.map(product => ({
                  ...product,
                  metadata: { ...product.metadata, [fieldName]: newValue },
                }))
                dispatch(updateProducts(updatedProducts))
              }}
              onDeleteImage={updatedProduct => dispatch(updateProducts([updatedProduct]))}
              selectedObjects={selectedProducts}
            />
          </SelectedProductProvider>
        </div>
      )}
    </Panel>
  )
}

export default SelectedProductPanel
