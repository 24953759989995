import { graphql } from '~/gql'

export const GET_ALL_VARIATIONS_ON_PRODUCT = graphql(`
  query GetAllVariationsOnProduct($productId: ID!, $isHeater: Boolean!) {
    Product(id: $productId) {
      defaultVariation {
        id
      } 
      variations {
        ...ProductVariationFragment @skip(if: $isHeater)
        ...HeaterVariationFragment @include(if: $isHeater)
      }
    }
  }
`)
