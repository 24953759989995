import flatten from 'lodash-es/flatten'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import isEqual from 'lodash-es/isEqual'

import ArrowRenderer from './arrowRenderer'
import Primitives from './primitives'
import Raycaster2D from './raycaster2d'
import { Raycaster } from 'three'
import Region from './region'
import Units from './units'
import Util from './util'

import {
  addCeiling,
  updateCeiling,
  deleteCeiling,
  updateObjects,
  updateObjectsPassively,
} from 'store/objects'
import { selectObjects } from 'store/selectedObjects'
import store from '~/store'
import LAYER_KEYS from 'config/layerKeys'
import OBJECT_TYPES from 'config/objectTypes'
import { getSelectedObjects } from 'store/selectedObjects/selectors'
import { getDistanceUnits } from 'store/units/selectors'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'

import * as THREE from 'three'
import { sample } from 'lodash-es'
import theme from '~/config/theme'
const DISTANCE_THRESHOLD = 1000

class Facility {
  // Measurement styles
  static SURFACE = Symbol('SURFACE')
  static CENTER = Symbol('CENTER')
  /** @type {Facility} current */
  static current

  /** @param {THREE.Raycaster} raycaster */
  constructor(raycaster) {
    this.walls = /** @type {Wall[]} */([])
    this.roofs = /** @type {Roof[]} */([])
    this.utilityBoxes = /** @type {UtilityBox[]} */([])
    this.roofSections = /** @type {RoofSection[]} */([])
    this.products = /** @type {Product[]} */([])
    this.comfortZones = /** @type {ComfortZone[]} */([])
    this.obstructions = /** @type {Obstruction[]} */([])
    this.airflow = /** @type {Airflow[]} */([])
    this.backgroundImage = /** @type {BackgroundImage} */({})
    this.cfd = /** @type {CFD[]} */([])
    this.cfdGrid =  /** @type {CFDGrid} */({})
    this.heatMap =  /** @type {HeatMap[]} */[]

    this.obj3d = new THREE.Object3D()

    const boxMin = new THREE.Vector3(
      Number.MAX_VALUE,
      Number.MAX_VALUE,
      Number.MAX_VALUE
    )
    const boxMax = new THREE.Vector3(
      -Number.MAX_VALUE,
      -Number.MAX_VALUE,
      -Number.MAX_VALUE
    )
    this.bbox = new THREE.Box3(boxMin, boxMax)

    this.raycaster = raycaster

    this.showDebugBounds = false
    this.defaultSize = Units.feetToNative(300)

    ArrowRenderer.subscribe(this)
  }

  // Takes a box3 and returns center of each box edge
  getArrowDescriptionStartPoints(box) {
    const points = []
    const topLeft = new THREE.Vector3(box.min.x, box.max.y, box.max.z)
    const topRight = new THREE.Vector3(box.max.x, box.max.y, box.max.z)
    const bottomLeft = new THREE.Vector3(box.min.x, box.min.y, box.max.z)
    const bottomRight = new THREE.Vector3(box.max.x, box.min.y, box.max.z)
    const hDistance = topLeft.distanceTo(topRight) / 2
    const vDistance = topLeft.distanceTo(bottomLeft) / 2
    points.push(Util.getPositionBetweenTwoVectors(topLeft, topRight, hDistance))
    points.push(
      Util.getPositionBetweenTwoVectors(bottomLeft, bottomRight, hDistance)
    )
    points.push(
      Util.getPositionBetweenTwoVectors(topRight, bottomRight, vDistance)
    )
    points.push(
      Util.getPositionBetweenTwoVectors(topLeft, bottomLeft, vDistance)
    )

    return points
  }

  getArrowDescriptions = () => {
    // Cleanup old box helper
    if (this.boxHelper) {
      Facility.current.obj3d.remove(this.boxHelper)
      this.boxHelper = null
    }

    // Only show distance arrows if more than one object is selected
    const selectedObjects = getSelectedObjects()
    if (selectedObjects.length < 2) return

    let shouldForceUpdate = false

    // If a scene saved occured force update
    const needsSaved = store.getState().objectsPersistence.needsSaved
    if (this.lastNeedsSaveState !== needsSaved) {
      this.lastNeedsSaveState = needsSaved
      shouldForceUpdate = true
    }

    // If number of objects changed force update
    if (this.selectedObjectCount !== selectedObjects.length) {
      this.selectedObjectCount = selectedObjects.length
      shouldForceUpdate = true
    }

    if (shouldForceUpdate) this.forceUpdateArrowRenderer()

    // Get objects that can safely be updated via text input
    const editableObjects = selectedObjects.filter(
      obj => obj.className === 'Product' || obj.className === 'Obstruction'
    )
    const descriptions = []
    const selectedIds = selectedObjects.map(obj => obj.id)
    const invalidLayers = [LAYER_KEYS.DIMENSIONS]
    const objects = Facility.current
      .getAllObjects()
      .filter(obj => selectedIds.includes(obj.id))
      .filter(obj => !invalidLayers.includes(obj.layerKey))

    // If less than two valid objects are selected do nothing
    if (objects.length < 2) return

    // Create a container object that will contain all of the selected objects
    this.containerObject = new THREE.Object3D()

    // Ensure there are no unwanted child objects to throw off our box (handles, etc)
    objects.forEach(obj => {
      const clone = obj.obj3d.clone()
      // For products we only care about the fan blade cylinder
      if (obj.layerKey === 'PRODUCTS') {
        const bladeCylinder = clone.children.filter(
          child => child.userData.objectType === 'FAN_BLADE_CYLINDER'
        )
        if (bladeCylinder) clone.children = bladeCylinder
      }
      clone.children = clone.children.filter(
        child => child.userData.objectType !== 'DRAG_HANDLE'
      )
      this.containerObject.add(clone)
    })

    // Create a selection box from the container objects and add it to the facility
    this.boxHelper = new THREE.BoxHelper(this.containerObject, 0x000000)
    Facility.current.obj3d.add(this.boxHelper)

    // If nothing changed return old descriptions
    const box = new THREE.Box3().setFromObject(this.containerObject)
    if (isEqual(box, this.lastBox)) {
      if (this.arrowDescriptions) return this.arrowDescriptions
    }

    const points = this.getArrowDescriptionStartPoints(box)
    const directions = [
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(0, -1, 0),
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(-1, 0, 0),
    ]
    const toWallOptions = {
      measureFrom: Facility.SURFACE,
      measureTo: Facility.SURFACE,
      includedTypes: [OBJECT_TYPES.WALL],
      xyOnly: true,
    }

    directions.forEach((direction, index) => {
      const originPoint = points[index]
      const measurements = Facility.current.measureObjectsInDirectionFromPoint(
        direction,
        originPoint,
        toWallOptions
      )

      measurements.sort((a, b) => a.distance - b.distance)

      if (measurements.length > 0) {
        const measurement = measurements[0]
        const arrowKey = `x:${direction.x},y:${direction.y},z:${direction.z}`
        const keyPressHandler = e => {
          if (e.which === 13) {
            // Enter was pressed
            const value = get(e, 'target.value', '').trim()
            const distanceUnits = getDistanceUnits(store.getState())
            const distance = new Distance({
              value: Distance.unformat({
                value: value,
                system: distanceUnits,
              }),
              system: distanceUnits,
            })

            if (distance.value === null) return

            distance.convertTo(SYSTEMS.IMPERIAL)
            const nativeValue = distance.native()
            const startPos = measurement.vector.clone()
            const endPos = direction.multiplyScalar(nativeValue).clone()
            const delta = startPos.sub(endPos)
            const facilityObjects = editableObjects.map(obj =>
              Facility.current.getAllObjects().find(o => o.id === obj.id)
            )
            const updatedObjects = facilityObjects.map(obj => {
              obj.move(obj.obj3d.position.clone().add(delta))
              return obj.toModel()
            })

            store.dispatch(updateObjects(updatedObjects))

            this.forceUpdateArrowRenderer()
          }
        }

        // If the state is waiting to be saved we don't allow editing of multiple
        // objects at once due to unexpected results that can happen after the save
        const isOnlyEditableObjects =
          editableObjects.length === selectedObjects.length
        const isValidEditState = !needsSaved && isOnlyEditableObjects

        const description = {
          key: arrowKey,
          vector: new THREE.Vector3(
            Units.inchesToNative(Units.nativeToInches(measurement.vector.x)),
            Units.inchesToNative(Units.nativeToInches(measurement.vector.y)),
            0
          ),
          position: points[index],
          showLength: true,
          editable: isValidEditState,
          keyPressHandler,
          options: {
            reinitializeKeypressHandler: false,
          },
        }

        descriptions.push(description)
      }
    })

    this.arrowDescriptions = descriptions
    this.lastBox = box.clone()

    return descriptions
  }

  forceUpdateArrowRenderer = () => {
    ArrowRenderer.unsubscribe(this)
    this.arrowDescriptions = null
    ArrowRenderer.subscribe(this)
  }

  addWall(wall) {
    this.walls.push(wall)
    this.obj3d.add(wall.obj3d)
  }

  removeWall(wall) {
    wall.destroy()

    const index = this.walls.findIndex(curWall => curWall === wall)
    this.walls.splice(index, 1)
    this.obj3d.remove(wall.obj3d)
  }

  removeWallWithId(id) {
    const wallWithId = this.walls.find(wall => wall.id === id)

    if (wallWithId) {
      this.removeWall(wallWithId)
    }
  }

  addRoof(roof) {
    this.roofs.push(roof)
    this.obj3d.add(roof.obj3d)

    const wall = this.walls.find(curWall => curWall.roofId === roof.id)
    if (wall) {
      wall.roof = roof
    }
  }

  removeRoof(roof) {
    roof.destroy()

    Util.remove(this.roofs, roof)
    this.obj3d.remove(roof.obj3d)
    const wall = this.walls.find(curWall => curWall.roofId === roof.id)
    if (wall) {
      wall.roof = undefined
    }
  }

  removeRoofWithId(id) {
    const roofWithId = this.getRoofWithId(id)

    if (roofWithId) {
      this.removeRoof(roofWithId)
    }
  }

  getRoofWithId(id) {
    return this.roofs.find(roof => roof.id === id)
  }

  addRoofSection(roofSection) {
    this.roofSections.push(roofSection)
    this.obj3d.add(roofSection.obj3d)
  }

  removeRoofSection(roofSection) {
    roofSection.destroy()

    Util.remove(this.roofSections, roofSection)
    this.obj3d.remove(roofSection.obj3d)
  }

  getRoofSectionWithId(id) {
    return this.roofSections.find(roofSection => roofSection.id === id)
  }

  removeRoofSectionWithId(id) {
    const roofSectionWithId = this.getRoofSectionWithId(id)

    if (roofSectionWithId) {
      this.removeRoofSection(roofSectionWithId)
    }
  }

  addUtilityBox(utilityBox) {
    this.utilityBoxes.push(utilityBox)
    this.obj3d.add(utilityBox.obj3d)
  }

  removeUtilityBox(utilityBox) {
    utilityBox.destroy && utilityBox.destroy()
    const index = this.utilityBoxes.findIndex(
      curUtilityBox => curUtilityBox === utilityBox
    )
    this.utilityBoxes.splice(index, 1)
    this.obj3d.remove(utilityBox.obj3d)
  }

  removeUtilityBoxWithId(id) {
    const utilityBoxWithId = this.utilityBoxes.find(
      utilityBox => utilityBox.id === id
    )

    if (utilityBoxWithId) {
      this.removeUtilityBox(utilityBoxWithId)
    }
  }

  addProduct(product) {
    this.products.push(product)
    this.obj3d.add(product.obj3d)
  }

  removeProduct(product) {
    product.destroy && product.destroy()
    const index = this.products.findIndex(curProd => curProd === product)
    this.products.splice(index, 1)
    this.obj3d.remove(product.obj3d)
  }

  removeProductWithId(id) {
    const productWithId = this.products.find(prod => prod.id === id)

    if (productWithId) {
      this.removeProduct(productWithId)
    }
  }

  addComfortZone(comfortZone) {
    this.comfortZones.push(comfortZone)
    this.obj3d.add(comfortZone.obj3d)
  }

  removeComfortZone(comfortZone) {
    comfortZone.destroy && comfortZone.destroy()
    const index = this.comfortZones.findIndex(curCZ => curCZ === comfortZone)
    this.comfortZones.splice(index, 1)
    this.obj3d.remove(comfortZone.obj3d)
  }

  removeComfortZoneWithId(id) {
    const comfortZoneWithId = this.comfortZones.find(cz => cz.id === id)

    if (comfortZoneWithId) {
      this.removeComfortZone(comfortZoneWithId)
    }
  }

  addObstruction(obstruction) {
    this.obstructions.push(obstruction)
    this.obj3d.add(obstruction.obj3d)
  }

  removeObstruction(obstruction) {
    obstruction.destroy()
    const index = this.obstructions.findIndex(curObs => curObs === obstruction)
    this.obstructions.splice(index, 1)
    this.obj3d.remove(obstruction.obj3d)
  }

  removeObstructionWithId(id) {
    const obsWithId = this.obstructions.find(obs => obs.id === id)

    if (obsWithId) {
      this.removeObstruction(obsWithId)
    }
  }

  addCFD(cfd) {
    this.cfd.push(cfd)
    this.obj3d.add(cfd.obj3d)
  }

  addCFDGrid(cfdGrid) {
    if (this.cfdGrid.destroy) {
      this.cfdGrid.destroy()
    }
    this.cfdGrid = cfdGrid
  }

  removeCFD() {
    while (this.cfd.length) {
      const cfd = this.cfd[0]
      cfd.destroy()
      Util.remove(this.cfd, cfd)
      this.obj3d.remove(cfd.obj3d)
    }
  }

  removeCFDGrid() {
    if (this.cfdGrid.destroy) {
      this.cfdGrid.destroy()
    }
    this.cfdGrid = {}
  }

  addHeatMap(heatMap) {
    this.heatMap.push(heatMap)
    this.obj3d.add(heatMap.obj3d)
  }

  removeHeatMap() {
    while (this.heatMap.length) {
      const heatMap = this.heatMap[0]
      heatMap.destroy()
      Util.remove(this.heatMap, heatMap)
      this.obj3d.remove(heatMap.obj3d)
    }
  }

  addAirflow(airflow) {
    this.airflow.push(airflow)
    this.obj3d.add(airflow.obj3d)
  }

  removeAirflow() {
    while (this.airflow.length) {
      const airflow = this.airflow[0]
      airflow.destroy()
      Util.remove(this.airflow, airflow)
      this.obj3d.remove(airflow.obj3d)
    }
  }

  getAirflowWithId(id) {
    return this.airflow.find(airflow => airflow.id === id)
  }

  findObjectWithId(id) {
    return this.getAllObjects().find(obj => obj.id === id)
  }

  addGridBox(gridBox) {
    this.removeGridBoxWithId()
    this.gridBox = gridBox
    selectObjects({ objects: [gridBox] })

    this.obj3d.add(gridBox.obj3d)
  }

  removeGridBoxWithId() {
    if (this.gridBox) {
      this.obj3d.remove(this.gridBox.obj3d)
      this.gridBox.destroy()
    }
    this.gridBox = null
  }

  center() {
    const boundingBox = new THREE.Box3().setFromObject(this.obj3d)
    const panX = boundingBox.min.x + this.getXSize() / 2
    const panY = boundingBox.min.y + this.getYSize() / 2

    if (panX === Infinity || panY === Infinity) return new THREE.Vector2(0, 0)

    return new THREE.Vector2(panX, panY)
  }

  /**
   * A point on a two dimensional plane.
   * @typedef {Object} Measurements
   * @property {Object} wrapper - Wrapper class object (if applicable)
   * @property {THREE.Object3D} obj3d - Vector going from original point to point of intersection with object
   * @property {THREE.Vector3} vector - Vector going from original point to point of intersection with object
   * @property {number} distance - The distance from the point to either the object's surface or center
   */
  /**
   * @param {THREE.Vector3} direction
   * @param {THREE.Vector3} startPoint
   * @param {Object} options
   * @param {string[]=} options.includedTypes Discard three.js nodes if their .userData.objectType isn't in this list
   * @param {string[]=} options.excludedTypes Discard three.js nodes if their .userData.objectType IS in this list
   * @param {THREE.Object3D[]=} options.excludedObjects Discard three.js nodes if they are in this list, or if they are a descendent of any node in the list.
   * @param {(Facility.SURFACE | Facility.CENTER)=} options.measureTo Whether to measure to an object's surface or center. Accepted values are Facility.SURFACE and Facility.CENTER
   * @param {boolean=} options.xyOnly Ignore the Z axis during measurements if true.
   * @param {boolean=} options.obstructionMode
   * @param {THREE.Object3D} object
   *
   * @description In order for `measureTo: Facility.CENTER` to work correctly, whatever object you're measuring to the center of must use a material with `side: THREE.DoubleSide`
   * @returns {Measurements[]}
   */
  measureObjectsInDirectionFromPoint(direction, startPoint, options, object) {
    options = options || {}

    const raycaster = this.raycaster
    /** @type {THREE.Object3D[]} */
    let objects = []

    raycaster.set(startPoint, direction)

    Util.walkNodes(this.obj3d, node => {
      if (
        node.geometry instanceof THREE.BufferGeometry
      ) {
        objects.push(node)
      }
    })

    // Filter out UI elements
    objects = objects.filter(
      object =>
        !(
          object.userData.objectType &&
          object.userData.objectType.startsWith('UI_')
        )
    )

    // Filter out CFD
    objects = objects.filter(object => !(object.layerKey === LAYER_KEYS.CFD))

    // Filter intersections based on given included/excluded types
    if (options.includedTypes) {
      objects = objects.filter(object =>
        options.includedTypes.includes(object.userData.objectType)
      )
    }
    if (options.excludedTypes) {
      objects = objects.filter(
        object => !options.excludedTypes.includes(object.userData.objectType)
      )
    }
    if (options.excludedObjects) {
      objects = objects.filter(
        object =>
          !options.excludedObjects.some(excludedObj =>
            Util.nodeDescendsFromNode(object, excludedObj)
          )
      )
    }

    let intersections = []

    if (options.xyOnly && options.obstructionMode) {
      // Filter out any objects not in the right direction
      objects = this.filterObjectsByXYDirection(
        objects,
        startPoint,
        direction,
        object,
        options
      )

      // Find wall intersects
      const walls = objects.filter(
        obj => get(obj, 'userData.objectType') === 'WALL'
      )
      const wallIntersects =
        walls.map(object => raycaster.intersectObject(object, false)).flat()

      // Try to hit remaining objects that are closer first
      const remainingObjects = objects.filter(
        obj => get(obj, 'userData.objectType') !== 'WALL'
      )
      let remainingIntersects
      for (let i = 0; i < remainingObjects.length; i++) {
        const intersects = raycaster.intersectObject(remainingObjects[i], false)
        // Stop if we hit something
        if (intersects.length) {
          remainingIntersects = intersects
          break
        }
      }

      intersections = [...wallIntersects, ...remainingIntersects ?? []]
    } else {
      intersections = objects.map(object => { try { return raycaster.intersectObject(object, false) } catch (e) { return [] } }).flat()
    }

    // Filter out extremely close objects (most likely self-intersections)
    if (!options.obstructionMode) {
      intersections = intersections.filter(
        intersection => intersection.distance > 0.1
      )
    }

    intersections.sort((a, b) => a.distance - b.distance)

    // We group intersections by those sharing the same .object property. When
    // there are multiple intersections on one object like this it's because
    // the ray penetrated multiple surfaces of the object. We take advantage
    // of this to find an approximate center point of the mesh.
    /** @type {Record<string, THREE.Intersection[]>} */
    const intersectionGroups = {}
    intersections.forEach(intersection => {
      const group = intersectionGroups[intersection.object.uuid] || []
      group.push(intersection)
      intersectionGroups[intersection.object.uuid] = group
    })

    // Find furthest two intersections per group, and filter out the rest
    Object.keys(intersectionGroups).forEach(key => {
      const group = intersectionGroups[key]

      if (group.length > 2) {
        const furthestTwo = Util.getFurthestTwoPoints(
          group.map(intersection => intersection.point)
        )
        const keepers = group.filter(
          intersection =>
            Util.pointsAreEqual3D(intersection.point, furthestTwo[0]) ||
            Util.pointsAreEqual3D(intersection.point, furthestTwo[1])
        )
        intersectionGroups[key] = keepers
      }
    })

    const measureStyle = options.measureTo || Facility.SURFACE
    let uniqueIntersections

    if (measureStyle === Facility.SURFACE) {
      // Only keep the nearest intersection
      uniqueIntersections = Object.keys(intersectionGroups).map(
        key => intersectionGroups[key][0]
      )
    } else if (measureStyle === Facility.CENTER) {
      uniqueIntersections = Object.keys(intersectionGroups).map(key => {
        const group = intersectionGroups[key]
        const intersection = group[0]

        // We can only find the center if the raycast intersected both sides of the object
        if (group.length > 1) {
          const nearestPoint = group[0].point
          const furthestPoint = group[1].point

          // Update the 'point' property of the intersection to hold the center point
          intersection.point = new THREE.Vector3().lerpVectors(nearestPoint, furthestPoint, 0.5)
        }

        return intersection
      })
    }

    return uniqueIntersections.map(intersection => {
      const vector = intersection.point.sub(startPoint)
      return {
        wrapper: this.findObjectWithId(intersection.object.wrapperId),
        obj3d: intersection.object,
        vector,
        distance: vector.length(),
      }
    })
  }

  /**
   * @param {THREE.Vector3} direction
   * @param {THREE.Object3D} object
   */
  measureObjectsInDirectionFromObject(direction, object, options) {
    const measureFrom = options.measureFrom || Facility.SURFACE
    const measureTo = options.measureTo || Facility.SURFACE

    let startPoint = object.position.clone()

    if (measureFrom === Facility.SURFACE) {
      object.geometry.computeBoundingSphere()
      const bSphere = object.geometry.boundingSphere
      const scale = Math.max(object.scale.x, object.scale.y) + 1

      // This sphere surface point actually has twice the radius of
      // the bounding sphere since the point we're trying to hit on
      // the mesh could be touching the bounding sphere, which would
      // cause problems; however, there is no issue with casting
      // the ray from arbitrarily further out.
      const sphereSurfacePoint = object.position
        .clone()
        .addScaledVector(direction, bSphere.radius * scale)
      const inverseDirection = direction.clone().multiplyScalar(-1)

      this.raycaster.set(sphereSurfacePoint, inverseDirection)
      const intersections = this.raycaster.intersectObject(object, true)

      if (intersections.length > 0) {
        const nearestObjectSurfacePoint = intersections[0].point

        // We chose a point that is a tiny bit out (in the direction we intend to measure in) from
        // the object surface, otherwise we risk hitting the object we're measuring /from/.
        startPoint = nearestObjectSurfacePoint.addScaledVector(
          direction,
          Units.inchesToNative(0.0001)
        )
      }
    }
    if (options.measureFromFloor) {
      startPoint.setZ(10)
    }

    const revisedOptions = {
      ...options,
      measureTo,
      excludedObjects: [object].concat(options.excludedObjects),
    }
    const hits = this.measureObjectsInDirectionFromPoint(
      direction,
      startPoint,
      revisedOptions,
      object
    )

    // We want to include startPoint in the result since it is computed here
    // when using `measureFrom: Facility.Surface` and may not be known to
    // the caller of this method.
    return hits.map(hit => ({ ...hit, startPoint }))
  }

  /*
    Given a starting point and range, returns an array of objects, one for each
    scene object which was in range. The returned object format is:

    If 'range' is set to null, an infinite range will be used.

      {
        wrapper,  // Wrapper class object (if applicable)
        obj3d,    // Three.js scene graph node
        vector,   // Vector going from original point to point of intersection with object
        distance, // Distance from point to object
      }

    The options object allows:
    includedTypes: Discard three.js nodes if their .userData.objectType isn't in this list
    excludedTypes: Discard three.js nodes if their .userData.objectType IS in this list
    measureInvisibleObjects: Whether we should filter out non-visible objects
    sort: whether the results should be sorted by nearest to the given point
  */
  measureObjectsInRangeOfPoint(point, range, options = {}) {
    if (!point) {
      console.warn(
        "'point' must be defined when calling 'measureObjectsInRangeOfPoint'"
      )
      return []
    }

    let objects = []

    let inverseMatrix = new THREE.Matrix4()
    let sphere = new THREE.Sphere()

    const skipRangeFilter = range === null

    Util.walkNodes(this.obj3d, node => {
      if (node.geometry instanceof THREE.BufferGeometry) {
        objects.push(node)
      }
    })

    // Alway filter out any objects whose 'type' begins with "UI_"
    // These objects have no counterparts to physical facilities,
    // so we don't want to measure them.
    objects = objects.filter(object => {
      return !(
        object.userData.objectType &&
        object.userData.objectType.startsWith('UI_')
      )
    })

    // Filter objects based on given included/excluded types
    if (options.includedTypes) {
      objects = objects.filter(object =>
        options.includedTypes.includes(object.userData.objectType)
      )
    }
    if (options.excludedTypes) {
      objects = objects.filter(
        object => !options.excludedTypes.includes(object.userData.objectType)
      )
    }

    if (!options.measureInvisibleObjects) {
      objects = objects.filter(obj => obj.visible)
    }

    if (!skipRangeFilter) {
      // Initial distance filter, based on bounding spheres
      // and the given range. Purely an optimization.
      objects = objects.filter(mesh => {
        if (!mesh.geometry.boundingSphere) {
          mesh.geometry.computeBoundingSphere()
        }

        const matrixWorld = mesh.matrixWorld
        sphere.copy(mesh.geometry.boundingSphere)
        sphere.applyMatrix4(matrixWorld)

        const pointToCenterDistance = sphere.center.sub(point).length()
        return pointToCenterDistance < range + sphere.radius
      })
    }

    // Actual measurement takes place here
    objects = objects
      .map(mesh => {
        const triangles = Util.getTrianglesFromGeometry(mesh.geometry)

        const matrixWorld = mesh.matrixWorld
        inverseMatrix.copy(matrixWorld).invert()

        let localPoint
        if (point.clone) {
          localPoint = point.clone().applyMatrix4(inverseMatrix)
        } else {
          localPoint = new THREE.Vector3(
            point.x,
            point.y,
            point.z
          ).applyMatrix4(inverseMatrix)
        }

        let closestPoint
        let smallestDistanceSq = Number.MAX_VALUE

        triangles.forEach(triangle => {
          let thisClosestPoint = new THREE.Vector3(0, 0, 0)
          triangle.closestPointToPoint(localPoint, thisClosestPoint)
          const thisDistanceSq = thisClosestPoint.distanceToSquared(localPoint)

          if (thisDistanceSq < smallestDistanceSq) {
            smallestDistanceSq = thisDistanceSq
            closestPoint = thisClosestPoint
          }
        })

        // If there was something wrong with some geometry in the scene, we may
        // not have been able to find any closestPoint
        if (closestPoint) {
          closestPoint = closestPoint.applyMatrix4(matrixWorld)
          mesh.userData._temp_vectorToClosestPoint = closestPoint.sub(point)

          return mesh
        } else {
          return null
        }
      })
      .filter(a => !!a) // Remove nulls

    if (!skipRangeFilter) {
      objects = objects.filter(
        mesh => mesh.userData._temp_vectorToClosestPoint.length() < range
      )
    }

    const results = objects.map(mesh => {
      const result = {
        wrapper: this.findObj3dOwner(mesh),
        obj3d: mesh,
        vector: mesh.userData._temp_vectorToClosestPoint,
        distance: mesh.userData._temp_vectorToClosestPoint.length(),
      }

      mesh.userData._temp_vectorToClosestPoint = undefined

      return result
    })

    if (options.sort) {
      results.sort((a, b) => a.distance - b.distance)
    }

    return results
  }

  findObj3dOwner(obj3d) {
    // This is the bare minimum, but can be made more comprehensive
    return this.findObjectWithId(obj3d.wrapperId)
  }

  /*
    SceneBuilder event
  */
  sceneDidRebuild(modifiedCategories, modifiedObjects) {
    const productsChanged = modifiedCategories.has(LAYER_KEYS.PRODUCTS)
    const roofChanged = modifiedCategories.has(LAYER_KEYS.ROOFS)
    const wallsChanged =
      modifiedCategories.has(LAYER_KEYS.EXTERIOR_WALLS) ||
      modifiedCategories.has(LAYER_KEYS.INTERIOR_WALLS)

    if (wallsChanged) {
      this.updateRegions()
      this.updateCeilings()
      this.updateProducts()
    }

    if (roofChanged || wallsChanged) {
      setTimeout(() => {
        this.updateFullHeightWalls()
      }, 500)
    }

    if (productsChanged) {
      setTimeout(() => {
        this.updateProducts(modifiedObjects)
      }, 1000)
    }
  }

  updateFullHeightWalls() {
    const fullHeightWallSegments = this.getWallSegments().filter(
      seg => seg.isFullHeight
    )

    if (fullHeightWallSegments.length) {
      const updatedSegments = []
      fullHeightWallSegments.forEach(segment => {
        const centerLinePoints = segment.getCenterLinePoints()
        // 1. raycast for height of each endpoint
        const startHeight = Util.getDistanceFromFloor(
          this,
          Util.arrayPointToObjectPoint(centerLinePoints[0])
        )
        const endHeight = Util.getDistanceFromFloor(
          this,
          Util.arrayPointToObjectPoint(centerLinePoints[1])
        )

        // 2. take shorter height and update segment accordingly
        let height = Math.min(startHeight, endHeight)

        // 3. If interior wall is connect to exterior, the height will be 0.
        // In this case, take the height of that exterior wall
        if (height === 0) {
          height = Util.getHeightOfConnectedExteriorWall(
            Util.arrayPointToObjectPoint(centerLinePoints[0]),
            Util.arrayPointToObjectPoint(centerLinePoints[1])
          )
        }

        // 4. Don't update if we're not changing height
        if (height > 0 && segment.height !== height) {
          segment.height = height - Units.inchesToNative(12)
          updatedSegments.push(segment.toModel())
        }
      })
      if (updatedSegments.length) {
        store.dispatch(updateObjects(updatedSegments))
      }
    }
  }

  /*
    Add, update, and delete ceilings so that they continue to match the Regions formed
    by the current set of interior and exterior walls.
  */
  updateCeilings() {
    const roomRegions = this.getRoomRegions()
    const ceilings = Object.values(store.getState().objects.present.ceilings)

    if (roomRegions.length === 0 && ceilings.length === 0) return

    const ceilingsToDeleteCount = Math.max(
      ceilings.length - roomRegions.length,
      0
    )
    const ceilingsToAddCount = Math.max(roomRegions.length - ceilings.length, 0)

    // Algorithm overview:
    //
    // - Build map: Ceiling -> Region, where the associated region is the one with
    //   the highest 'polygon similarity'.
    // - Delete Ceilings for which no associated region was detected
    // - Add Ceilings for each Region which was not associated to a Ceiling.
    // - Update perimeter points of each Ceiling that did not have a perfect polygon similarity
    //   with it's associated Region.

    /** @type {Map<import('~/store/objects/types').Ceiling, Region>} */
    const ceilingsToRegions = new Map()
    const unmatchedRegions = roomRegions.slice()
    /** @type {import('~/store/objects/types').Ceiling[]} */
    const unmatchedCeilings = []
    ceilings.forEach(ceiling => {
      if (unmatchedRegions.length === 0)
        return ceilingsToRegions.set(ceiling, null)

      const regionSimilarities = unmatchedRegions.map(region => ({
        region,
        similarity: Util.polygonSimilarity(
          ceiling.perimeterPoints,
          region.points
        ),
      }))

      // Sort by descending similarity
      regionSimilarities.sort((a, b) => b.similarity - a.similarity)
      const highestSimiliarity = get(regionSimilarities, '0.similarity')

      if (highestSimiliarity > 0) {
        const mostSimilarRegion = get(regionSimilarities, '0.region')
        ceilingsToRegions.set(ceiling, mostSimilarRegion)
        Util.remove(unmatchedRegions, mostSimilarRegion)
      } else {
        ceilingsToRegions.set(ceiling, null)
        unmatchedCeilings.push(ceiling)
      }
    })

    // If there is exactly one unmatched region and one unmatched ceiling
    // The area was likely moved to a completely new boundary
    // So we want to match the ceiling and region
    if (unmatchedRegions.length === 1 && unmatchedCeilings.length === 1) {
      const region = unmatchedRegions[0]
      const ceiling = unmatchedCeilings[0]
      ceilingsToRegions.set(ceiling, region)
      Util.remove(unmatchedRegions, region)
    }

    const ceilingsWithoutRegions = Array.from(ceilingsToRegions.entries())
      .filter(entry => entry[1] === null) // Find entries with null values
      .map(entry => entry[0]) // Take the keys (ceilings) from those entries
    const ceilingsToDelete = ceilingsWithoutRegions

    const foundCorrectNumberToDelete =
      ceilingsToDeleteCount === ceilingsToDelete.length

    const foundCorrectNumberToAdd =
      ceilingsToAddCount === unmatchedRegions.length

    console.assert(
      foundCorrectNumberToDelete,
      `Error when updating ceilings: incorrect number of ceilings to delete; should be ${ceilingsToDeleteCount}, but found ${ceilingsToDelete.length}`
    )
    console.assert(
      foundCorrectNumberToAdd,
      `Error when updating ceilings: incorrect number of ceilings to add; should be ${ceilingsToAddCount}, but found ${unmatchedRegions.length}`
    )

    ceilingsToDelete.forEach(ceiling =>
      store.dispatch(deleteCeiling({ ceiling }))
    )
    unmatchedRegions.forEach(region =>
      store.dispatch(
        addCeiling({ ceiling: {
          id: Util.guid(),
          layerKey: LAYER_KEYS.CEILINGS,
          enabled: false,
          color: sample(theme.colors.swatches),
          perimeterPoints: region.points,
          height: 97,
        }})
      )
    )

    for (let [ceiling, region] of ceilingsToRegions) {
      if (region !== null) {
        const similarity = Util.polygonSimilarity(
          ceiling.perimeterPoints,
          region.points
        )
        if (similarity !== 1) {
          store.dispatch(
            updateCeiling({
              ceiling: {
                ...ceiling,
                perimeterPoints: region.points,
                ignoreForCFD: true,
              },
              ignoreForCFD: true,
            })
          )
        }
      }
    }
  }

  updateProducts(modifiedObjects) {
    const products = this.getProducts()
    const updatedObjects = get(modifiedObjects, 'updated', [])
    const modifiedIds = updatedObjects.map(obj => obj.id)

    // Update all products besides the ones that were just updated
    if (products.length) {
      const productModels = []
      this.getProducts()
        .filter(product => !modifiedIds.includes(product.id))
        .forEach(product => {
          const model = product.position && product.toModel()
          if (model) productModels.push(model)
        })
      if (productModels.length) {
        store.dispatch(updateObjectsPassively(productModels))
      }
    }
  }

  /*
    Builds a tree of nested Regions rooted at this.regionRoot (which itself has no shape,
    just a starting point for accessing the tree). The algorithm for this is roughly:

    - Create Regions for all polygon Walls in the Facility, arrange them in a tree to reflect nesting [0].
    - For each polyline Wall, find the 'deepest' (i.e. most nested) Region already
      in the tree which contains the polyline's midpoint.
      - If a Region is found, attempt to split that Region into two child Regions
        along the polyline.
      - If it was possible to perform the split (both ends of the polyline touched edges of
        the Region), remove the polyline Wall from the list of polyline Walls, and add the two
        sub-Regions resulting from the split as children of the Region which they split.
    - Keep going through the list of of remaining polyline Walls as above until it's detected
      that no new regions were created on the last attempt.

    Notes:
    [0]: The initial tree created by nesting the initial polygon walls may not be correct, since some
          parent region may later be split, so that a child should belong to one of the split regions
          instead. This is corrected for in the 'addChild' method of Region.
  */
  getRegions(walls = this.getWalls().slice()) {
    const regionRoot = new Region([])
    const polygonWalls = walls.filter(wall => wall.isPolygon())
    const polylineWalls = walls.filter(wall => !wall.isPolygon())

    const initialRegions = polygonWalls.map(
      wall => new Region(wall.globalCenterLinePoints())
    )

    this._treeifyRegions(initialRegions, regionRoot)

    /** @type {Wall[]} */
    const wallsToRemove = []
    let moreRegionsCouldBeSplit = true

    do {
      polylineWalls.forEach(wall => {
        const wallPoints = wall.globalCenterLinePoints()
        const polylineMidPoint = Util.getPolylineMidPoint(wallPoints)

        const parentRegion = regionRoot.findDeepestRegionContainingPoint(
          polylineMidPoint
        )
        const subRegions = parentRegion.split(wallPoints)

        if (subRegions) {
          parentRegion.addChild(subRegions[0], true)
          parentRegion.addChild(subRegions[1], true)
          wallsToRemove.push(wall)
        }
      })

      moreRegionsCouldBeSplit = wallsToRemove.length > 0

      wallsToRemove.forEach(wall => {
        Util.remove(polylineWalls, wall)
      })
      wallsToRemove.length = 0
    } while (moreRegionsCouldBeSplit)

    return regionRoot
  }

  updateRegions() {
    this.regionRoot = this.getRegions(this.getWalls().slice())
  }

  /*
    Finds which Regions in the list contain others, and builds up a tree to reflect this
    containment hierarchy. The regions which were contained by no others are added to
    the root.
  */
  _treeifyRegions(regions, root) {
    regions.sort(
      (region1, region2) =>
        Util.polygonArea(region1.points) - Util.polygonArea(region2.points)
    )

    for (let i = 0; i < regions.length - 1; i += 1) {
      const region = regions[i]

      for (let j = i + 1; j < regions.length; j += 1) {
        const biggerRegion = regions[j]
        if (
          Util.isPointInPolygon(
            Util.polygonCentroid(region.points),
            biggerRegion.points
          )
        ) {
          biggerRegion.addChild(region)
          regions[i] = null
          break
        }
      }
    }

    regions.forEach(region => {
      if (region !== null) {
        root.addChild(region)
      }
    })
  }

  // Gets facility area in square inches.
  // NOTE: Uses inset wall points to get more accurate area of facility interior
  getFacilityAreaInSquareInches() {
    const exteriorWall = this.walls.find(
      wall =>
        wall.layerKey === 'EXTERIOR_WALLS' || wall.category === 'EXTERIOR_WALLS'
    )
    if (!exteriorWall) return []
    const segments = flatten(this.walls.map(wall => wall.segments))
    const numerator = segments
      .filter(segment => exteriorWall.segments.some(s => s.id === segment.id))
      .reduce(
        (acc, segment) =>
          acc +
          (Units.nativeToInches(segment.insetPoints[0][0]) *
            Units.nativeToInches(segment.insetPoints[1][1]) -
            Units.nativeToInches(segment.insetPoints[0][1]) *
              Units.nativeToInches(segment.insetPoints[1][0])),
        0
      )
    const area = numerator / 2
    return Math.abs(area)
  }

  filterObjectsByXYDirection(objects, startPoint, direction, object, options) {
    // We can't trust the position of walls so we always add them since
    // there is a high possibility that we will intersect one at some point
    let walls = []
    if (options.includedTypes.includes('WALL')) {
      walls = objects.filter(obj => get(obj, 'userData.objectType') === 'WALL')
    }

    let obstructions = []
    if (options.includedTypes.includes('OBSTRUCTION')) {
      obstructions = objects.filter(
        obj => get(obj, 'userData.objectType') === 'OBSTRUCTION'
      )
    }

    const box = new THREE.Box3().setFromObject(object)
    const box2 = new THREE.Box3()

    // Filter obstructions that are in the direction we want based on position
    let filteredObjects
    if (direction.x === 1) {
      filteredObjects = obstructions.filter(
        obj => obj.parent.position.x > startPoint.x
      )
      box.max.x = box.max.x + DISTANCE_THRESHOLD
    } else if (direction.x === -1) {
      filteredObjects = obstructions.filter(
        obj => obj.parent.position.x < startPoint.x
      )
      box.min.x = box.min.x - DISTANCE_THRESHOLD
    } else if (direction.y === 1) {
      filteredObjects = obstructions.filter(
        obj => obj.parent.position.y > startPoint.y
      )
      box.max.y = box.max.y + DISTANCE_THRESHOLD
    } else if (direction.y === -1) {
      filteredObjects = obstructions.filter(
        obj => obj.parent.position.y < startPoint.y
      )
      box.min.y = box.min.y - DISTANCE_THRESHOLD
    }

    // Use bounding boxes to filter out objects that aren't in line of sight
    let foundObjects = []
    filteredObjects.forEach(obj => {
      obj.geometry.computeBoundingBox()
      box2.setFromObject(obj)
      const isInside = box.intersectsBox(box2)
      if (isInside) {
        foundObjects.push(obj)
      }
    })

    // Sort remaining objects by vector distance based on direction
    let sortedObjects = []
    if (direction.x === 1) {
      sortedObjects = sortBy(foundObjects, 'parent.position.x')
    } else if (direction.x === -1) {
      sortedObjects = sortBy(foundObjects, 'parent.position.x')
      sortedObjects.reverse()
    } else if (direction.y === 1) {
      sortedObjects = sortBy(foundObjects, 'parent.position.y')
    } else if (direction.y === -1) {
      sortedObjects = sortBy(foundObjects, 'parent.position.y')
      sortedObjects.reverse()
    }

    return [...sortedObjects, ...walls]
  }

  getRegionTree() {
    return this.regionRoot
  }

  getExteriorWallRegions() {
    const root = this.getRegionTree()
    return root.getChildren()
  }

  getRoomRegions() {
    const roomRegions = /** @type {Region[]} */([])

    if (!this.getRegionTree()) {
      this.updateRegions()
    }

    this.walkRegions(this.getRegionTree(), region => {
      if (region && region.getChildren().length === 0 && region.points) {
        roomRegions.push(region)
      }
    })

    return roomRegions
  }

  getRegionMeshes(leavesOnly) {
    const meshes = []
    this.getRegionMeshesAux(this.regionRoot.getChildren(), meshes, leavesOnly)

    return meshes
  }

  /**
   * @param {Region} region
   * @param {(region: Region) => void} func
   */
  walkRegions(region, func) {
    if (!region) return
    func(region)

    const children = region.getChildren()
    for (let i = 0; i < children.length; i += 1) {
      this.walkRegions(children[i], func)
    }
  }

  getRegionMeshesAux(regions, list, leavesOnly) {
    regions.forEach(region => {
      if (!leavesOnly || region.getChildren().length === 0) {
        list.push(Primitives.getRegionMesh(region.points))
      }
      this.getRegionMeshesAux(region.getChildren(), list, leavesOnly)
    })
  }

  getAllObjects() {
    const objects = [
      ...this.getWalls(),
      ...this.getWallSegments(),
      ...this.getProducts(),
      ...this.getUtilityBoxes(),
      ...this.getComfortZones(),
      ...this.getRoofs(),
      ...this.getRoofSections(),
      ...this.getElevationPoints(),
      ...this.getElevationLines(),
      ...this.getObstructions(),
      ...this.getAirflow(),
      ...this.getCFD(),
      this.getCFDGrid(),
      ...this.getHeatMap(),
    ]
    const gridBox = this.getGridBox()
    if (gridBox) {
      objects.push(gridBox)
    }

    return objects
  }

  getWalls() {
    return this.walls
  }

  getWallHeight() {
    const wallWithRoof = this.getWalls().find(wall => wall.roofId)
    const sortedSegments = sortBy(wallWithRoof.segments, 'height')
    const highestSegment = sortedSegments[sortedSegments.length - 1]
    const heightInInches = Units.nativeToInches(highestSegment.height)

    return heightInInches
  }

  getWallWithId(id) {
    return this.walls.find(wall => wall.id === id)
  }

  /** @returns {WallSegment[]} */
  getWallSegments() {
    return flatten(this.walls.map(wall => wall.segments))
  }

  getProducts() {
    return this.products
  }

  getUtilityBoxes() {
    return this.utilityBoxes
  }

  getComfortZones() {
    return this.comfortZones
  }

  getRoofs() {
    return this.roofs
  }

  getRoofSections() {
    return this.roofSections
  }

  getElevationPoints() {
    return flatten(this.roofs.map(roof => roof.elevationPoints)).concat(
      this.getElevationLinePoints()
    )
  }

  getElevationLinePoints() {
    return flatten(this.getElevationLines().map(line => line.elevationPoints))
  }

  getElevationLines() {
    return flatten(this.roofs.map(roof => roof.elevationLines))
  }

  getObstructions() {
    return this.obstructions
  }

  getAirflow() {
    return this.airflow
  }

  getHeatMap() {
    return this.heatMap
  }

  getGridBox() {
    return this.gridBox
  }

  getCFD() {
    return this.cfd
  }

  getCFDGrid() {
    return this.cfdGrid
  }

  getXCompositeSize() {
    const boundingBox = Util.computeCompositeBoundingBox(this.obj3d)
    const sizeX = Math.abs(boundingBox.min.x - boundingBox.max.x)

    if (sizeX === Infinity) return this.defaultSize

    return sizeX
  }

  getYCompositeSize() {
    const boundingBox = Util.computeCompositeBoundingBox(this.obj3d)
    const sizeY = Math.abs(boundingBox.min.y - boundingBox.max.y)

    if (sizeY === Infinity) return this.defaultSize

    return sizeY
  }

  getXSize() {
    const boundingBox = new THREE.Box3().setFromObject(this.obj3d)
    const sizeX = Math.abs(boundingBox.min.x - boundingBox.max.x)

    if (sizeX === Infinity || isNaN(sizeX)) return this.defaultSize

    return sizeX
  }

  getYSize() {
    const boundingBox = new THREE.Box3().setFromObject(this.obj3d)
    const sizeY = Math.abs(boundingBox.min.y - boundingBox.max.y)

    if (sizeY === Infinity || isNaN(sizeY)) return this.defaultSize

    return sizeY
  }

  /*
    Interactifier event handler
  */
  objectWasDragged(draggedObject, dragDelta) {}
}

export default Facility
