import {
  BufferGeometry,
  DoubleSide,
  ExtrudeGeometry,
  Mesh,
  MeshBasicMaterial,
  Raycaster,
  Shape,
  Vector3,
} from 'three'
import Units from '~/components/DrawingCanvas/lib/units'
import { useAppSelector } from '~/store/hooks'
import { Product } from '~/store/objects/types'
import cdt2d from 'cdt2d'

const directions = [
  new Vector3(0, 1, 0),
  new Vector3(0, -1, 0),
  new Vector3(1, 0, 0),
  new Vector3(-1, 0, 0),
  new Vector3(1, 1, 0),
  new Vector3(1, -1, 0),
  new Vector3(-1, 1, 0),
  new Vector3(-1, -1, 0),
]

const useWallMesh = () => {
  const segments = useAppSelector(state => state.objects.present.segments)
  const walls = Object.values(segments)
  const shape = new Shape()
  walls.forEach((segment, i, arr) => {
    const isFirst = i === 0
    const isLast = arr.length === i - 1
    const { end, start } = segment.insetPoints
    if (isFirst) {
      shape.moveTo(start.x, start.y)
    } else {
      shape.lineTo(start.x, start.y)
    }
    if (isLast) shape.lineTo(end.x, end.y).closePath()
  })
  return new Mesh(
    new ExtrudeGeometry(shape, { steps: 1, depth: walls[0]?.height ?? 0, bevelEnabled: false }),
    new MeshBasicMaterial({ side: DoubleSide })
  )
}

const useRoofMesh = () => {
  const elevationPointsState = useAppSelector(state => state.objects.present.elevationPoints)
  const elevationLineState = useAppSelector(state => state.objects.present.elevationLines)
  const roofsState = useAppSelector(state => state.objects.present.roofs)

  const perimeterPoints = Object.values(roofsState)
    .map<[number, number, number][]>(({ perimeterPoints, height }) =>
      perimeterPoints.map(([x, y]) => [x, y, height])
    )
    .flat()
  perimeterPoints.pop() // Remove duplicate start/end point
  const edgeIndices = perimeterPoints.reduce<[number, number][]>((indices, _, i, { length }) => {
    indices.push([i, (i + 1) % length])
    return indices
  }, [])
  const elevationPointIndices = new Map(
    Object.values(elevationPointsState).map(({ id }, i) => [id, i + perimeterPoints.length])
  )
  Object.values(elevationLineState).forEach(({ elevationPointIds }) => {
    const [idA, idB] = elevationPointIds
    const indexA = elevationPointIndices.get(idA ?? '')
    const indexB = elevationPointIndices.get(idB ?? '')
    if (!indexA || !indexB) return
    edgeIndices.push([indexA, indexB])
  })
  const elevationPoints = Object.values(elevationPointsState).map<[number, number, number]>(
    ({ position }) => {
      const { x, y, z } = position
      return [Units.nativeToInches(x), Units.nativeToInches(y), Units.nativeToInches(z)]
    }
  )
  const points = perimeterPoints.concat(elevationPoints)
  const points3d = points.map(([x, y, z]) => new Vector3(x, y, z))
  const triangles = cdt2d(points, edgeIndices, { exterior: false })

  const geometry = new BufferGeometry().setFromPoints(points3d)
  geometry.setIndex(triangles.flat())
  geometry.computeVertexNormals()

  return new Mesh(geometry, new MeshBasicMaterial({ side: DoubleSide }))
}

export const useCheckFoilCollisions = () => {
  const wallMesh = useWallMesh()
  const roofMesh = useRoofMesh()

  return (product: Product) => {
    const { x, y, z } = product.position
    const position = new Vector3(x, y, z)
    const raycaster = new Raycaster()
    const radius = product.size / 2
    return directions.some(direction => {
      direction.normalize()
      raycaster.set(position, direction)
      const wallIntersections = raycaster.intersectObject(wallMesh)
      const roofIntersections = raycaster.intersectObject(roofMesh)
      const intersections = wallIntersections.concat(roofIntersections)
      const distance = intersections.reduce(
        (prev, { distance }) => (prev = Math.min(prev, distance)),
        Infinity
      )
      return distance < radius
    })
  }
}
