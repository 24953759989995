import React from 'react'
import { useAppSelector } from "~/store/hooks";
import compact from 'lodash-es/compact'
import * as panelsLibrary from '../Panels'

export default function PanelManager(props) {
  const { panel, objects } = useAppSelector(state => ({ panel: state.panel, objects: state.objects }))
  const visiblePanels = compact(Object.keys(panel).map(side => {
    const visibleKey = panel[side].visiblePanel
    return panel[side][visibleKey]
  }))
  const panels = visiblePanels.map((panel) => {
    const Component = panelsLibrary[panel.component]
    if (props.forceAlignment) {
      return (
        <Component
          {...{ objects, ...props }}
          alignment={props.forceAlignment}
          key={panel.component}
        />
      )
    }
    return <Component {...{ objects, ...props }} key={panel.component} />
  })
  return panels
}
